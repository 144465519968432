import React, {useState} from 'react';
import {
  Card, Grid, Button, Icon,
} from 'semantic-ui-react';
import { removeProduct } from './actions';
import CircularImage from '../../components/CircularImage';

import './styles.css';
import OrderModalBadge from "../../components/OrderCard/OrderModalBadge";

export default function CartProduct(props) {

  const { dispatch, order } = props;
  const [openBadgeModal, setOpenBadgeModal] = useState(false);

  function removeItem() {
    dispatch(removeProduct(order.id));
  }

  function printItem() {
    setOpenBadgeModal(true);
  }

  return (
      <Card centered className="cart-product">
        <Card.Content onClick={printItem} className="cart-product-content">
          <Grid doubling>
            <Grid.Row centered key={order.id}>
              <Grid.Column width={3} textAlign="center" className="first-column">
                <CircularImage className="cart-product-image"
                               src={order.file_image ?? "https://react.semantic-ui.com/images/wireframe/square-image.png"}
                               width={50}
                               circular={false}
                />
              </Grid.Column>
              <Grid.Column width={13} className="second-column break-words" textAlign="left">
                {'отвес № ' + order.seq_plant_number + ' ' +
                    (order.sku_type === 'minerals' ? 'МИНЕРАЛЫ' : 'ВИТАМИНЫ')}
              </Grid.Column>
              <div className="cart-buttons">
                <Button icon className="cart-print cart-button-wide" color="green" onClick={printItem}>
                  <Icon name="print" />
                </Button>
                <Button icon className="cart-delete" color="red" onClick={removeItem}>
                  <Icon name="trash" />
                </Button>
              </div>
            </Grid.Row>
          </Grid>
        </Card.Content>
        <OrderModalBadge
            orderID = {order.id}
            openBadgeModal = {openBadgeModal}
            setOpenBadgeModal = {setOpenBadgeModal}
            {...props}
        />
      </Card>
    );
}
