import React, {useState} from 'react';
import _ from 'lodash';
import {
  Card, Grid, Button, Icon,
} from 'semantic-ui-react';
import CircularImage from '../../components/CircularImage';

import './styles.css';
import ModalScale from "../ModalScale";
import {updateOrder} from "../../views/Orders/actions";
import ModalPrompt from "../ModalPrompt";
import {useSelector} from "react-redux";
import {getOrders} from "../../views/Orders/reducer";

export default function OrderProduct(props) {

  const {product, show_buttons, mixpanel, userData, userLocation, dispatch, order, token} = props;
  const [state, setState] = useState({
    scaleInProgress: false,
  });
  const [open, setOpen] = useState(false);

  const orders =  useSelector( (state) => getOrders(state.orders));

    let scaledState = "no-scaled";
    if (product.multiScale) {
        const totalScaled = product.multiData.filter((row) => row.scaled > 0).length;
        scaledState = totalScaled === 0
            ? "no-scaled"
            : (totalScaled === product.multiData.length
                ? "scaled" : "part-scaled");
    } else
        scaledState = product.scaled ? "scaled" : "no-scaled";

  function getProductSelections() {
    if (_.isNil(product.selections)) {
      return null;
    }

    const description = Object.keys(product.selections)
      .map((key) => _.startCase(key) + ': ' + product.selections[key])
      .join(', ');

    return (
      <Grid.Row>
        <Grid.Column width={16}>{description}</Grid.Column>
      </Grid.Row>
    );
  }

  function addScale() {
    if (scaledState === 'scaled')
      return;

    setState({ scaleInProgress: true });
  }

  function removeScale() {
    order.line_items[product.order_item_index].scaled = 0;
    setOpen(false);
    autoSaveOrderScale();
  }

  function saveScale(savedOrder, sku, data) {
    //setState({ scaleInProgress: false });
      console.log('save scale', savedOrder, sku, data, savedOrder.line_items.length);
      for (let i=0; i<savedOrder.line_items.length; i++) {
          console.log('check line item', savedOrder.line_items[i]);
          if (savedOrder.line_items[i].sku === sku) {
              savedOrder.line_items[i].scaled = Number(data.scale.scale);
              console.log('saved scale');
          }
      }

    //order.line_items[product.order_item_index].scaled = Number(data.scale.scale);
    autoSaveOrderScale(savedOrder);
  }

  function autoSaveOrderScale(savedOrder = order) {
    const data = {
      scaled_items: savedOrder.line_items,
      user_location: userLocation,
      user_data: userData,
    };

    dispatch(updateOrder(token, savedOrder.id, JSON.stringify(data), savedOrder.order_key, true));
  }

  function closeScale() {
    setState({ scaleInProgress: false });
  }

  const partScales = [];
  if (product.multiScale) {
    for (let i = 0; i < product.multiData.length; i++) {
      const orderProduct = product.multiData[i];
      const itemOrder = orders.find((obj) => Number(obj.id) === Number(orderProduct.order_id),);
      partScales.push(
          <>
            <Grid.Column width={11} className="first-column">
              <div
                  dangerouslySetInnerHTML={{
                    __html: 'отвес ' + itemOrder.seq_plant_number + ' / ' + itemOrder.seq_order_number,
                  }}
              />
            </Grid.Column>
            <Grid.Column width={2} className="third-column">
              <div
                  dangerouslySetInnerHTML={{
                    __html: orderProduct.quantity / 1000 + ' кг',
                  }}
              />
            </Grid.Column>
            <Grid.Column width={2} textAlign="right" className="fourth-column">
              <div
                  dangerouslySetInnerHTML={{
                    __html: orderProduct.scaled / 1000 + ' кг',
                  }}
              />
            </Grid.Column>
          </>
      )
    }
  } else
    partScales.push(
        <>
          <Grid.Column width={2} className="third-column">
            <div
                dangerouslySetInnerHTML={{
                  __html: product.quantity / 1000 + ' кг',
                }}
            />
          </Grid.Column>
          <Grid.Column width={2} textAlign="right" className="fourth-column">
            <div
                dangerouslySetInnerHTML={{
                  __html: product.scaled / 1000 + ' кг',
                }}
            />
          </Grid.Column>
        </>
    );


  return (
      <>
        <Card centered
              className={"cart-product " + scaledState}
              color={scaledState === "scaled"
                  ? 'green'
                  : ( scaledState === "no-scaled" ? 'red' : 'yellow')}
        >
          <Card.Content onClick={addScale}>
            <Grid doubling>
              <Grid.Row centered>
                <Grid.Column width={3} textAlign="center" className="first-column">
                  <CircularImage className="cart-product-image" src={product.image} width={50} circular={false}/>
                </Grid.Column>
                <Grid.Column width={8} className="second-column break-words" textAlign="left">
                  {product.name}
                </Grid.Column>
                {partScales}
                { show_buttons ?
                    <div className="cart-buttons">
                      <Button icon onClick={addScale} color="green" disabled={scaledState === "scaled"} className="cart-button-wide">
                        <Icon name="balance scale" />
                      </Button>
                        { product.multiScale ? '' :
                          <Button icon className="cart-delete" onClick={() => (setOpen(true))} color="red"
                                  disabled={scaledState === "no-scaled"}>
                            <Icon name="trash" />
                          </Button>
                        }
                    </div> : ''}
              </Grid.Row>
              {getProductSelections()}
            </Grid>
          </Card.Content>
        </Card>

        { state.scaleInProgress ?
            <ModalScale
                product={product}
                open={state.scaleInProgress}
                handleOpen={addScale}
                handleClose={closeScale}
                callback={saveScale}
                mixpanel={mixpanel}
                userData={userData}
                userLocation={userLocation}
                closeOnDimmerClick={false}
                {...props}
            /> : ''}

        {scaledState === 'scaled' || scaledState === 'part-scaled' ?
            <ModalPrompt
                open={open}
                headerIcon="question"
                headerText="Подтверждение"
                messageText="Не удаляйте результат взвешивания, если данная порция уже добавлена в насыпь. Восстановить результат будет не возможно! Действительно удалить? "
                handleYes={removeScale}
                handleNo={() => setOpen(false)}
                handleClose={() => setOpen(false)}
                handleOpen={() => setOpen(true)}
            /> : ''}
      </>
  );
}

