import React, {useRef, useState} from 'react';
import { useSelector } from 'react-redux';

import {
    Card, Loader, Button, Dimmer, Divider, Modal, Header, Icon, Container
} from 'semantic-ui-react';
import {getPlaceOrderInProgress} from "../../views/Orders/reducer";
import MiniOrderCard from "./MiniOrderCard";
import OrderCardHeader from "./OrderCardHeader";
import {addProduct} from "../../views/Cart/actions";
import {isCountriesFetching} from "../AddressCard/reducer";

import OrderProduct from "./OrderProduct";
import OrderSummary from "./OrderSummary";
import OrderProductHeader from "./OrderProductHeader";
import {getProducts} from "../../views/Products/reducer";
import {createOrderQRCode, receiveOrderData, updateOrder} from "../../views/Orders/actions";
import {isObject} from "lodash";
import {useReactToPrint} from "react-to-print";
import {BadgeToPrint} from "../PrintComponent/PrintComponent";
import OrderModalBadge from "./OrderModalBadge";

export default function OrderCard(props) {

    const {order, dispatch, navigate, userData, userLocation, mixpanel, token} = props;

    if (!order.line_items)
        return (
            <Container>
                <p>Отвес не содержит компонентов для взвешивания, пожалуйста, проверьте исходную спецификацию и перевыгрузите заказ на производство</p>
            </Container>
        );

    const getLoading = useSelector((state) => getPlaceOrderInProgress(state.orders));
    const getLoadingCountries = useSelector((state) => isCountriesFetching(state.address));
    const products = useSelector((state) => getProducts(state.products));

    const [openBadgeModal, setOpenBadgeModal] = useState(false);

    const noScaledProduct = order.line_items.find((item) => typeof(item.scaled) === 'undefined' || item.scaled === 0);
    const notCompleted = isObject(noScaledProduct);

    function getProductDescription(productID) {
        const product = products.find((obj) => obj.id === Number(productID),);
        return product ? product.short_description : '';
    }

    function completeOrder() {
        //dispatch(receiveOrderData(order));
        const data = {
            //status: 'completed',
            scaled_items: order.line_items,
            user_location: userLocation,
            user_data: userData,
        };
        dispatch(updateOrder(token, order.id, JSON.stringify(data), order.order_key));
        //createOrderBadge();
    }

    function printOrderLabel() {

        setOpenBadgeModal(true);
    }

    //console.log('order states and props', props, noScaledProduct, notCompleted);

    return (
        <div>
            <Card centered color="orange" className="order-card">
                <Dimmer active={ [1, order.id].includes(getLoading) || getLoadingCountries } inverted>
                    <Loader className="order-update-loader"/>
                </Dimmer>
                <OrderCardHeader order={order} actionButtons={false} {...props}/>
                <MiniOrderCard
                    key={order.id}
                    order={order}
                    header={false}
                    circular={true}
                    actionButtons={false}
                    {...props}
                />
                <OrderProductHeader
                    name="НАИМЕНОВАНИЯ КОМПОНЕНТА"
                    quantity="ВЕС ПЛАН"
                    total="ВЕС ФАКТ"
                    idOrder={order.id}
                />
                {order.line_items.map((product, index) => {
                    return (
                    <OrderProduct
                        key={product.id}
                        product={{
                            id: product.id,
                            sku: product.sku,
                            order: order,
                            order_item_index: index,
                            quantity: product.quantity,
                            scaled: (product.scaled ?? 0),
                            image: product.image_src,
                            name: product.name,
                            short_description: product.product_description,
                            //mixpanel,
                            dispatch,
                            userData,
                            userLocation
                         }}
                        show_buttons={true}
                        {...props}
                    />
                )} )}
                <OrderSummary
                    order
                    quantity={ order.line_items.reduce((a, product) => {
                        return a + product.quantity;
                    }, 0) }
                    scaled = { order.line_items.reduce((a, product) => {
                        return a + (product.scaled ?? 0);
                    }, 0) }
                />
                <Divider/>
                <Button primary size="big" fluid onClick={printOrderLabel}>
                    Напечатать этикетку
                </Button>
                <OrderModalBadge
                    orderID = {order.id}
                    openBadgeModal = {openBadgeModal}
                    setOpenBadgeModal = {setOpenBadgeModal}
                    {...props}
                />
            </Card>
        </div>
    );
}