import React from "react";
import {
    Image
} from 'semantic-ui-react';

export class BadgeToPrint extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        function kitcut( text, limit) {
            text = text.trim();
            if( text.length <= limit) return text;
            text = text.slice( 0, limit); // тупо отрезать по лимиту
            let lastSpace = text.lastIndexOf(" ");
            if( lastSpace > 0) { // нашлась граница слов, ещё укорачиваем
                text = text.slice(0, lastSpace);
            }
            return text + " ...";
        }

        const {order} = this.props;
        const {billing} = order;
        const qrcode = order.qrcode ?? "https://react.semantic-ui.com/images/wireframe/square-image.png";

        const
            styleImageQR = {
                width:"600px",
                margin:"-1.5em",
            },
            styleFirstCol = {
                width:"50%",
                margin:"0 -1.5em 0 0",
            },
            styleSecondCol = {
                width:"calc(50% + 3em)",
                margin: "0.5em 0 0 -0.5em",
            },
            styleOneCol = {
                width:"100%",
                margin:"0",
            },
            styleMainText = {
                width:"100%!important",
                fontSize:"25px",
                fontWeight:"bold",
                color:"black",
                margin:"0.25em 0.25em",
            },
            styleSecondaryText = {
                width:"100%!important",
                fontSize: "14px",
                fontWeight:"400",
                color:"black",
                margin:"0 0.25em",
            },
            styleBottomText = {
                width:"100%!important",
                fontSize: "14px",
                fontWeight:"400",
                color:"black",
                margin:"00.5em 0.25em 0.5em 0.75em",
            },
            styleFirstRow = {
                display: "flex",
            };

        return (
            <div>
                <div className="order-badge-first-row" style={styleFirstRow}>
                    <div className="order-badge-first-col" style={styleFirstCol}>
                        <Image src={qrcode} size="medium" className="qr-code" style={styleImageQR}/>
                    </div>
                    <div className="order-badge-second-col" style={styleSecondCol}>
                        <p style={styleSecondaryText}>
                            {'ЗП ' + order.source_customer_order_id}
                            <br/>
                            {order.spec_name}
                        </p>
                        <p style={styleMainText}>
                            {order.sku_type === 'minerals' ? 'МИНЕРАЛЫ' : 'ВИТАМИНЫ'}
                            <br/>
                            {'ОТВЕС № ' + order.seq_plant_number}
                        </p>
                        <p style={styleSecondaryText}>
                            {'Плановый вес: ' + (order.total_quantity / 1000) + ' кг.'}
                            <br/>
                            {'Фактический вес: ' + (order.total_scaled / 1000) + ' кг.'}
                        </p>
                        <p/>

                    </div>
                </div>
                <div className="order-badge-second-row">
                    <div className="order-badge-one-col" style={styleOneCol}>
                        <p className="company uc" style={styleBottomText}>
                            {kitcut(order.source_product_name,300)}
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export class ProductLabelToPrint extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {


        const {id, name, product_qrcode} = this.props;
        const qrcode = product_qrcode ?? "https://react.semantic-ui.com/images/wireframe/square-image.png";

        const
            styleImageQR = {
                width: "400px",
                margin: "-1.5em",
            },
            styleFirstCol = {
                width: "66%",
                margin: "0 -1.5em 0 0",
            },
            styleSecondCol = {
                width:"34%",
                margin: "1.5em 0 0 -0.5em",
            },
            styleOneCol = {
                width:"100%",
                margin:"0",
            },
            styleName = {
                width:"100%!important",
                fontSize:"15px",
                fontWeight:"bold",
                textAlign:"center",
                color:"black",
                margin:"0 0.25em",
            },
            styleFirstRow = {
                display: "flex",
            };

        return (
            <div>
                <div className="order-badge-first-row" style={styleFirstRow}>
                    <div className="order-badge-first-col" style={styleFirstCol}>
                        <Image src={qrcode} size="medium" className="qr-code" style={styleImageQR}/>
                    </div>
                    <div className="order-badge-second-col" style={styleSecondCol}>
                        <p style={styleName}>
                            {name}
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}
