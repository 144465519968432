import React from 'react';
import _ from 'lodash';
import {useSelector} from 'react-redux';
import {
  Icon, Label, Menu,
} from 'semantic-ui-react';
import {closeMenu, openMenu} from './actions';
import { getCart } from '../../views/Cart/reducer';
import {SERVICE_TASK_LEVEL_PREPARING, SERVICE_TASK_LEVEL_WORKING} from "../../config/config";

export default function NavBottomBar (props) {

  const {menuVisible, dispatch, navigate, token} = props;

  const getCartQuantity = useSelector((state) => getCart(state.cart));

  function showSidebar(e) {
    e.stopPropagation();
    dispatch( menuVisible ? closeMenu() : openMenu());
  }

  return (
      <Menu
          fluid
          secondary
          id="nav-bottom-bar"
          className="nav-bottom-bar"
          fixed="bottom"
          color="blue"
          icon="labeled"
          inverted
      >
        <Menu.Item className="shop-icon-item" onClick={() => navigate("/")}>
            <Icon name="home" size="tiny" className="shop-icon" />
            <div className="shop-text-item">Компоненты</div>
        </Menu.Item>
          <Menu.Item name="tasks" className="shop-icon-item" onClick={() => navigate("/orders-grouped")}>
            <Icon name="tasks" size="tiny" className="shop-icon"/>
              <div className="shop-text-item">Заказы</div>
          </Menu.Item>
        <Menu.Item name="tasks" className="shop-icon-item" onClick={() => navigate("/orders")}>
          <Icon name="boxes" size="tiny" className="shop-icon"/>
          <div className="shop-text-item">Отвесы</div>
        </Menu.Item>
          <Menu.Item className="shop-icon-item" onClick={() => navigate("/cart")}>
            <Icon name="law" size="tiny" className="shop-icon" />
            {_.isEmpty(getCartQuantity) ? '' :
                <Label
                    color="blue"
                    size="mini"
                    floating
                    circular
                    content={getCartQuantity.length}
                    className="cart-counter"
                />
            }
              <div className="shop-text-item">Платформа</div>
          </Menu.Item>
          <Menu.Item className="shop-icon-item" onClick={showSidebar}>
          <Icon name="content" size="tiny" className="shop-icon" />
              <div className="shop-text-item">Меню</div>
          </Menu.Item>
      </Menu>
  );
}