const GLOBAL_VARIABLES= window.appticles.config;
export default GLOBAL_VARIABLES;
export const AUTH_INVALID_TOKEN = 'jwt_auth_invalid_token';
export const SERVICE_LOGICAL_ACCESS_DENIED = 400;
export const AUTH_INVALID_MESSAGE = 'Вы не авторизованы, войдите в приложение для корректной работы';
export const TINKOFF_SUCCESS_PAGE = '/order-success';

export const SERVICE_TASK_STATE_IN_PROGRESS = 'in-progress';
export const SERVICE_TASK_STATE_CANCELED = 'canceled';

export const SERVICE_TASK_LEVEL_DISABLED = 0;   // level 0 - услуга не доступна для работы и изменения статуса
export const SERVICE_TASK_LEVEL_PREPARING = 1;  // level 1 - услуга еще не доступна для работы
export const SERVICE_TASK_LEVEL_WORKING = 2;   // level 2 - услуга доступна для работы
export const SERVICE_TASK_LEVEL_CLOSED = 3;    // level 3 - услуга закрыта и не доступна для работы

export const SHOW_TASK_PROMPT_CONFIRM = 1;
export const SHOW_TASK_PROMPT_DATE = 2;

export const SERIAL_DEFAULT_BAUD_RATE = 9600;

export const WEIGHT_DEVIATION = 1; // отклонение в процентах