import { combineReducers } from 'redux';
import _ from 'lodash';
import {ADD_PRODUCT, REMOVE_PRODUCT, REMOVE_ALL_PRODUCTS} from './actions';

const items = (state = [], action) => {
  switch (action.type) {
    case ADD_PRODUCT:
        const now = new Date();
        const newProduct = {
            id: action.data.id,
            ...action.data,
            dateAdded: now.toString(),
        };
        return _.unionBy(state, [newProduct], 'id');

    case REMOVE_PRODUCT:
      return state.filter((item) => item.id !== action.id);

    case REMOVE_ALL_PRODUCTS:
      return [];

    default:
      return state;
  }
};

export const getCart = (state) => state.items;

export default combineReducers({
  items,
});
