import React from 'react';
import { Header } from 'semantic-ui-react';
import GeoLocation from './GeoLocation/GeoLocation';
import MiniOrderCard from "./OrderCard/MiniOrderCard";
import _ from "lodash";

export default function OrdersList(props) {

  const { title, orders, elementFilter } = props;
  const list = orders.map((element) => {
      if (_.isEmpty(element)) return;
      return (
          <MiniOrderCard
              key={element.id}
              order={element}
              header={true}
              circular={true}
              actionButtons={true}
              {...props}
          />);
  });

  return (
      <div>
        <Header textAlign="center">{title}</Header>
        {_.isUndefined(elementFilter) ? '' : elementFilter}
        {list}
      </div>
    );
}
