import 'whatwg-fetch';
import config from '../../config/config';
import {toastr} from "react-redux-toastr";

export const REQUEST_DOC = 'REQUEST_DOC';
export const RECEIVE_DOC = 'RECEIVE_DOC';
export const RECEIVE_1PAGE_DOC = 'RECEIVE_1PAGE_DOC';
export const DOC_PAGE_COUNT = 20;

export const requestDoc = (page) => ({
    type: REQUEST_DOC,
    page,
});

export const receiveDoc = (doc) => ({
    type: RECEIVE_DOC,
    doc,
});

export const receive1PageDoc = (doc) => ({
    type: RECEIVE_1PAGE_DOC,
    doc,
});

export const fetchDoc = (params = {}) => (dispatch) => {
    dispatch(requestDoc(params.page ?? 1));

    let url;
    if (params && params.id) {
        url = config.API_DOC_URL + String(params.id);
    } else {
        url = config.API_DOC_URL
            + '?'
            + Object.keys(params)
                .map((k) => k + '=' + encodeURIComponent(params[k]))
                .join('&');
    }

    return fetch(url)
        .then((response) => response.json())
        .then((json) => {
            if (json.code === 200)
            {
                dispatch(params.page === 1 ? receive1PageDoc(json.data) : receiveDoc(json.data))
            }
        })
        .catch( (ex) => toastr.error('Ошибка запроса: ' + String(ex)) );
};