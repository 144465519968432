import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import _, {isNull} from 'lodash';
import { toastr } from 'react-redux-toastr';
import 'react-image-gallery/styles/css/image-gallery.css';
import {
  Header, Card, Icon, Button, Accordion,
} from 'semantic-ui-react';
import ImageGallery from 'react-image-gallery';
import { addProduct } from '../Cart/actions';
import Rating from '../../components/Rating';
import Reviews from '../../components/Reviews';
import Variations from '../../components/Variations';
import SocialBox from '../../components/SocialBox';
import config from '../../config/config';

import './styles.css';
import {getCart} from "../Cart/reducer";
import {Link} from "react-router-dom";
import {store} from "../../configureStore";
import {getVariations} from "../../components/Variations/reducer";

export default function ProductDetails(props) {

  const dispatch = useDispatch();
  const { product } = props;

  const [state, setState] = useState({
    stateSelections: null,
    stateVariationId: null,
  });

  const cart =  useSelector( state => getCart(state.cart));
  const variations = useSelector(state => getVariations(state.variations));

  const product_in_cart = !!cart.find(
      obj => obj.id === product.id && (isNull(obj.variationId) || obj.variationId == state.stateVariationId)
  );

  const variation = isNull(state.stateVariationId) ? null : variations.find(obj => obj.id == state.stateVariationId);

  const productPrice = isNull(state.stateVariationId) ? product.price : variation.price;

  function isAnyCached(images) {
    return images
      .map((image) => {
        const newImage = new Image();
        newImage.src = image.original;
        return newImage.complete;
      })
      .filter((isCached) => isCached === false);
  }

  function getCategories() {
    const { product } = props;
    return product.categories.map((category) => category.name).join(', ');
  }

  function getImageGallery() {
    const { product } = props;
    return product.images.map((image) => ({ original: image.src }));
  }

  /**
   * Modify component's state when a variation is selected.
   * @param {Object} selections
   * @param {Number} variationId
   */
  function receiveSelections(selections, variationId) {
    setState({ stateSelections: selections, stateVariationId: variationId });
  }

  /**
   * Add product to cart.
   * Display a warning if the product has variations and attributes were not selected.
   */
  function addItem() {
    const { product } = props;

    if (product.variations.length !== 0) {
      if (_.isNull(state.stateSelections)) {
        toastr.warning('Пожалуйста, выберите опции');
        return;
      }
    }
    dispatch(
      addProduct(
          product.id,
          product.name,
          isNull(variation) ? product.price : variation.price,
          product.images[0].src,
          isNull(variation) ? product.virtual : variation.virtual,
          state.stateVariationId,
          state.stateSelections,
      ),
    );

    toastr.success('Добавление в корзину', product.name + ' добавлен в корзину');
  }

  const descPanels = [
      {
        key: 'about',
        title: {
          content: 'О продукте',
        },
        content: {
          content: (
            <div dangerouslySetInnerHTML={{ __html: product.short_description }} />
          ),
        },
      },
      {
        key: 'desc',
        title: {
          content: 'Подробнее',
        },
        content: {
          content: (
            <div dangerouslySetInnerHTML={{ __html: product.description }} />
          ),
        },
      },
    ];

    const anyCached = isAnyCached(getImageGallery())[0] === false
      ? isAnyCached(getImageGallery())[0] : true;

    return (
      <div>
        <Header textAlign="center" className="break-words">
            {product.name}
        </Header>
        <Card centered>
            <ImageGallery
              items={getImageGallery()}
              slideDuration={550}
              showPlayButton={false}
              showThumbnails
              showNav={window.navigator.onLine || anyCached}
              disableSwipe={!window.navigator.onLine || !anyCached}
            />
            {product.rating_count > 0 ? (
              <Card.Content extra>
                <Rating
                  rating={Math.round(Number(product.average_rating))}
                  ratingCount={product.rating_count}
                />
              </Card.Content>
            ) : null}
            {product.categories.length === 0 ? null : (
              <Card.Content>{getCategories()}</Card.Content>
            )}
            {product.stock_status === 'instock' ? null : <Card.Content>Нет в наличии</Card.Content>}

            {product.variations.length === 0 ? null : (
              <Variations
                sendSelections={receiveSelections}
                productId={product.id}
                variationIds={product.variations}
              />
            )}

        </Card>
        {product.description.length === 0 ? null : (
          <Card centered>
            <Card.Content>
              <Card.Description>
                <Accordion
                  panels={descPanels}
                  defaultActiveIndex={0}
                />
              </Card.Description>
            </Card.Content>
          </Card>
        )}
        <Reviews productId={product.id} />
        <SocialBox
            permalink={config.HOME_URL + '#/product/' + product.id}
            title={"Ссылка на продукт <" + product.name + ">, рекомендую!"}
            body="Посмотри, есть крутой продукт!"
            image={product.images[0].src}
        />
      </div>
    );
}

