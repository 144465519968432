import {useState, useEffect,  useRef, useLayoutEffect} from 'react';

export function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return { width, height };
}

export function getElementDimensionsById(elementID) {
    const element = document.getElementById(elementID);
    if (_.isUndefined(element) || _.isNull(element))
        return { width: 0, height: 0 };
    const { offsetWidth: width, offsetHeight: height } = element;
    return { width, height };
}

export function useWindowDimensions() {
    const [dimensions, setDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return dimensions;
}

const isBrowser = typeof window !== 'undefined';

function getScrollPosition({ element, useWindow }) {
    if (!isBrowser) return { x: 0, y: 0 }

    const target = element ? element.current : document.body
    const position = target.getBoundingClientRect()

    return useWindow
        ? { x: window.scrollX, y: window.scrollY }
        : { x: position.left, y: position.top }
}

export function useScrollPosition(effect, deps, element, useWindow, wait) {
    const position = useRef(getScrollPosition({ useWindow }))

    let throttleTimeout = null

    const callBack = () => {
        const currPos = getScrollPosition({ element, useWindow })
        effect({ prevPos: position.current, currPos })
        position.current = currPos
        throttleTimeout = null
    }

    useLayoutEffect(() => {
        const handleScroll = () => {
            if (wait) {
                if (throttleTimeout === null) {
                    throttleTimeout = setTimeout(callBack, wait);
                }
            } else {
                callBack()
            }
        }

        window.addEventListener('scroll', handleScroll)

        return () => window.removeEventListener('scroll', handleScroll)
    }, deps)
}

export function setWindowScrollPosition(x = 0, y = 0) {
    window.scrollTo(x, y);
}

export function isEmptyObject(arg) {
    return typeof arg === 'object' && Object.keys(arg).length === 0;
}