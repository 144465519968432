import 'whatwg-fetch';
import { toastr } from 'react-redux-toastr';
import config from '../../config/config';
import { closeAuth } from '../NavTopBar/actions';

export const REQUEST_USER_DATA = 'REQUEST_USER_DATA';
export const RECEIVE_USER_DATA = 'RECEIVE_USER_DATA';
export const REQUEST_TOKEN = 'REQUEST_TOKEN';
export const RECEIVE_TOKEN = 'RECEIVE_TOKEN';
export const DROP_TOKEN = 'DROP_TOKEN';

export const requestUserData = () => ({
  type: REQUEST_USER_DATA,
});

export const receiveUserData = (data) => ({
  type: RECEIVE_USER_DATA,
  data,
});

export const requestToken = () => ({
  type: REQUEST_TOKEN,
});

export const receiveToken = (userName, json) => ({
  type: RECEIVE_TOKEN,
  data: {
    userName,
    userDisplayName: json.user_display_name,
    token: json.token,
    loggedIn: !!json.token,
    message: json.message,
    onDate: new Date(),
  },
});

export const dropToken = () => ({
  type: DROP_TOKEN,
  data: {
    loggedIn: false,
    message: 'Вы вышли из приложения ' + new Date(),
    onDate: new Date(),
  },
});

export const fetchUserData = (token) => (dispatch) => {
  dispatch(requestUserData());
  const headers = { Authorization: 'Bearer ' + token };

  return fetch(config.API_CURRENT_USER_URL, { headers })
    .then((response) => response.json())
    .then((json) => {
      dispatch(receiveUserData(json));
    })
    .catch((ex) => {
      dispatch(receiveUserData({ code: 401, message: ex }));
    });
};

export const fetchToken = (userName, userPassword) => (dispatch) => {
  dispatch(requestToken());

  return fetch(config.API_TOKEN_URL + '?username=' + userName + '&password=' + userPassword, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((json) => {
      dispatch(receiveToken(userName, json));
      if (json.token) {
        toastr.success('Добро пожаловать, ' + json.user_display_name + '!');
        dispatch(fetchUserData(json.token));
        dispatch(closeAuth());
      }
    })
    .catch((ex) => {
      dispatch(receiveToken(userName, { code: 401, message: ex }));
    });
};
