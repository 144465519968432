import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import {Loader, Container, Header, Dimmer, Segment, Checkbox} from 'semantic-ui-react';

import {
    fetchSearch,
    receiveSearch,
    SEARCH_CONTEXT_ORDERS, SEARCH_CONTEXT_PRODUCTS,
    SEARCH_CONTEXT_TASKS,
    SEARCH_CONTEXTS,
    SEARCH_PAGE_COUNT
} from './actions';
import {
    getSearchFetching,
    getSearch,
    getHasMore,
    getToContext,
    getFromContext
} from './reducer';
import ProductsList from '../../components/ProductsList';

import {useParams} from 'react-router';
import OrdersList from "../../components/OrdersList";

import "./style.css"
import InfiniteView from "../../components/InfiniteView";
import {setWindowScrollPosition} from "../../components/WindowDimensions";
import config from "../../config/config";
import OrdersGroupedList from "../../components/OrdersGroupedList";
import {Base64} from "../../App";

export function isSearchView(pathname) {
    return pathname.indexOf('search') !== -1;
}
export default function Search(props) {

  const {routeAction, location, dispatch, token, userLocation, userData, mixpanel, isPageReloaded} = props;

  const {search} = useParams();

  const loading = useSelector((state) => getSearchFetching(state.search));
  const searchItems = useSelector((state) => getSearch(state.search));
  const hasMore = useSelector((state) => getHasMore(state.search));
  const fromContext = useSelector((state) => getFromContext(state.search));
  const searchContext = useSelector((state) => getToContext(state.search));

  const context = SEARCH_CONTEXTS.find(obj => obj.value === fromContext);
  const contextTitle = _.isObject(context) ? context.title : '';
  const title = `Результаты поиска '${search}' ${contextTitle}`;

    const [showCompleted, setShowCompleted] = useState(false);

  useEffect(() => {
      if ( routeAction === 'PUSH' || isPageReloaded ) {
              window.scrollTo(0, 0);
              dispatch(receiveSearch(searchContext, 1, []));
              readSearch(1, searchContext);
          }
          if (mixpanel)
              mixpanel.track('Search List View',
                  {...userLocation, ...userData, search, searchContext, fromContext, routeAction, ...location});
  }, [search, searchContext, showCompleted]);

  useEffect(() => {
        if (isPageReloaded) {
            setWindowScrollPosition();
        }
  }, [props]);

  const loadMore = () => readSearch(Math.round(searchItems.length / SEARCH_PAGE_COUNT) + 1, searchContext);

  const readSearch = (page, context)  => {

      let orderField = 'desc';
      let orderByField = 'id';
      let filter =  '';
      switch (context) {
          case SEARCH_CONTEXT_TASKS:
              filter = showCompleted ? '' : Base64.encode("order_status = 'processing'");
              break;
          case SEARCH_CONTEXT_ORDERS:
              orderByField = 'order_plant_date';
              orderField = 'desc';
              filter = showCompleted ? '' : Base64.encode("order_status = 'processing'");
              break;
          case SEARCH_CONTEXT_PRODUCTS:
              orderByField = 'name';
              orderField = 'asc';
              break;
          default:
              break;
      }

      const params = {
          search,
          page,
          per_page: SEARCH_PAGE_COUNT,
          order: orderField,
          orderby: orderByField,
          home_category: config.HOME_CATEGORY
      };

      if (filter)
          params['filter'] = filter;

      dispatch(fetchSearch(
        token,
        context,
        params
    ));
  }

    const elementFilter = (
        <Segment className="order-filter-segment">
            <Checkbox
                className="filter-item-checkbox"
                toggle
                label="Показывать завершенные"
                defaultChecked={showCompleted}
                onChange={() => setShowCompleted(!showCompleted)}
            />
        </Segment>
    );

  if (!navigator.onLine)
      return (<Container><p>Нет подключения к Интернету</p></Container>);

  const loaderText = `Ищем '${search}' ${contextTitle}...`;
  const headerTitle = ` Результаты поиска '${search}' ${contextTitle}`;

  if (loading && (searchItems.length === 0 || fromContext !== searchContext)) {
      return (
          <Dimmer active={true} inverted className="page-loader">
              <Loader active>{loaderText}</Loader>
          </Dimmer>
      );
  }

  if (searchItems.length === 0 || !token) {
        return (
            <Container>
                <p>
                    {token
                        ? <>
                            <div className="filter-nothing-found-text">Ничего не найдено</div>
                            {[SEARCH_CONTEXT_TASKS, SEARCH_CONTEXT_ORDERS].includes(searchContext) ? elementFilter : ''}
                        </>
                        : 'Для получения доступа к списку, пожалуйста, авторизуйтесь'
                    }</p>
            </Container> );
  }

  return (
      <InfiniteView
          dataLength={searchItems.length}
          nextFetch={loadMore}
          hasMore={hasMore}
          dataList={(
              <>
                  { fromContext === SEARCH_CONTEXT_TASKS ?
                      <OrdersList
                          orders={searchItems}
                          title={title}
                          elementFilter={(
                              <Segment className="order-filter-segment">
                                  <Checkbox
                                      className="filter-item-checkbox"
                                      toggle
                                      label="Показывать завершенные"
                                      defaultChecked={showCompleted}
                                      onChange={() => setShowCompleted(!showCompleted)}
                                  />
                              </Segment>
                          )}
                          {...props}/>
                      : ''
                  }
                  { fromContext === SEARCH_CONTEXT_ORDERS ?
                      <OrdersGroupedList
                          orders={searchItems}
                          title={title}
                          elementFilter={(
                              <Segment className="order-filter-segment">
                                  <Checkbox
                                      className="filter-item-checkbox"
                                      toggle
                                      label="Показывать завершенные"
                                      defaultChecked={showCompleted}
                                      onChange={() => setShowCompleted(!showCompleted)}
                                  />
                              </Segment>
                          )}
                          {...props}/>
                      : ''
                  }
                  { fromContext === SEARCH_CONTEXT_PRODUCTS ?
                      <ProductsList
                          products={searchItems}
                          title={title}
                          {...props}/>
                      : ''
                  }
              </>
          )}
          mustAuth={(fromContext === SEARCH_CONTEXT_TASKS || fromContext === SEARCH_CONTEXT_ORDERS)}
          isAuth={!!token}
          isLoading={false}
          loaderText={loaderText}
          zeroText="Ничего не найдено"
      />
  );
}



