import React, { useEffect } from 'react';
import {useSelector} from 'react-redux';
import _ from 'lodash';
import { useParams } from 'react-router';
import OrderCard from "../../components/OrderCard";
import {fetchOrders} from "../Orders/actions";
import {getOrders} from "../Orders/reducer";
import {Dimmer, Loader} from "semantic-ui-react";

export default function Order(props) {

  const {token, dispatch, userData, userLocation, mixpanel} = props;

  const matchParams = useParams();
  const orderID = matchParams.orderId;
  const orders =  useSelector( (state) => getOrders(state.orders));

  const order = orders.find((obj) => Number(obj.id) === Number(orderID),);

  useEffect(() => {
    readOrder();
    if (mixpanel)
      mixpanel.track('Order Card View', { ...userLocation, ...userData, order });
    window.scrollTo(0, 0);
  }, [orderID]);

  const readOrder = () => {
    let params = {};
    if (_.isNil(order))
      params = { id: orderID };
    else
      params = { id: orderID, order_key: order.order_key ?? '' }
    dispatch(fetchOrders(token,params));
  };

  if (_.isNil(order)) {
    console.warn('Заказ для отображения не найден', orders, orderID);
    return  (
      <Dimmer active={ true } inverted>
        <Loader inverted> Загружаем заказ ...</Loader>
      </Dimmer>
      );
  }

  return (
      <div>
        <OrderCard
            order={order}
            {...props}
        />
      </div>
  );
}


