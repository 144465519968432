import { combineReducers } from 'redux';
import _ from 'lodash';
import {
  ORDERS_GROUPED_PAGE_COUNT, RECEIVE_1PAGE_ORDERS_GROUPED, RECEIVE_ORDERS_GROUPED, REQUEST_ORDERS_GROUPED
} from "./actions";

import {mergeObjectArrays} from "../../configureStore";

const isFetching = (state = false, action = {}) => {
  switch (action.type) {
    case RECEIVE_ORDERS_GROUPED:
    case RECEIVE_1PAGE_ORDERS_GROUPED:
      return false;
    case REQUEST_ORDERS_GROUPED:
      return (action.page === 1);
    default:
      return state;
  }
};

const items = (state = [], action = 0) => {
  switch (action.type) {
    case RECEIVE_ORDERS_GROUPED:
      return mergeObjectArrays(action.ordersGrouped, state);
    case RECEIVE_1PAGE_ORDERS_GROUPED:
      return action.ordersGrouped;
    default:
      return state;
  }
};

const hasMore = (state = false, action) => {
  switch (action.type) {
    case REQUEST_ORDERS_GROUPED:
      return true;
    case RECEIVE_ORDERS_GROUPED:
    case RECEIVE_1PAGE_ORDERS_GROUPED:
      return action.ordersGrouped.length >= ORDERS_GROUPED_PAGE_COUNT;
    default:
      return state;
  }
};

export const getOrdersGrouped = (state) => state.items;
export const getOrdersGroupedFetching = (state) => state.isFetching;
export const getOrdersGroupedHasMore = (state) => state.hasMore;

const ordersGrouped = combineReducers({
  isFetching, items, hasMore,
});

export default ordersGrouped;
