import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import _, {isArray} from 'lodash';
import {Loader, Container, Dimmer, Divider, Header, Segment, Checkbox} from 'semantic-ui-react';
import InfiniteScroll from 'react-infinite-scroll-component';

import {fetchOrdersGrouped, ORDERS_GROUPED_PAGE_COUNT} from "./actions";
import {
  getOrdersGrouped,
  getOrdersGroupedFetching,
  getOrdersGroupedHasMore,
} from "./reducer";

import "./style.css"
import InfiniteView from "../../components/InfiniteView";
import OrdersGroupedList from "../../components/OrdersGroupedList";
import {Base64} from "../../App";

export default function OrdersGrouped(props) {

  const {dispatch, userData, userLocation, mixpanel, routeAction, token, isPageReloaded} = props;

  const loading = useSelector((state) => getOrdersGroupedFetching(state.ordersGrouped));
  const orders = useSelector((state) => getOrdersGrouped(state.ordersGrouped));
  const hasMore = useSelector((state) => getOrdersGroupedHasMore(state.ordersGrouped));

  const [showCompleted, setShowCompleted] = useState(false);

  useEffect(() => {

    if ( routeAction === 'PUSH' || isPageReloaded ) {
      if (token)
        readOrders(1);
        window.scrollTo(0, 0);
    }

    if (mixpanel)
      mixpanel.track('OrdersGrouped List View', { ...userLocation, ...userData });


  }, [showCompleted]);

  useEffect(() => {
    if (isPageReloaded)
      window.scrollTo(0, 0);
  }, [props]);

  const loadOrders = () => {
    if (hasMore) {
      const nextPage = Math.round(orders.length / ORDERS_GROUPED_PAGE_COUNT) + 1;
      readOrders(nextPage);
    }
  };

  const readOrders = (page) => {
    const params = {
      page,
      per_page: ORDERS_GROUPED_PAGE_COUNT,
    };

    if (!showCompleted)
      params['filter'] = Base64.encode("order_status = 'processing'");

    dispatch(fetchOrdersGrouped(token, params));
  };

  const elementFilter = (
      <Segment className="order-filter-segment">
        <Checkbox
            className="filter-item-checkbox"
            toggle
            label="Показывать завершенные"
            defaultChecked={showCompleted}
            onChange={() => setShowCompleted(!showCompleted)}
        />
      </Segment>
  );

  if (loading) {
    return (
        <Dimmer active={ loading } inverted className="page-loader">
          <Loader>Загружаем заказы ...</Loader>
        </Dimmer>
    );
  }

  if (orders.length === 0 || !token) {
    return (
        <Container>
          <p>
            {token
                ? <>
                    <div className="filter-nothing-found-text">Заказы отсутствуют</div>
                    {elementFilter}
                  </>
                : 'Для получения доступа к списку, пожалуйста, авторизуйтесь'
            }</p>
        </Container> );
  }

  return (
      <>
          <InfiniteView
              dataLength={orders.length}
              nextFetch={loadOrders}
              hasMore={hasMore}
              dataList={(
                    <OrdersGroupedList
                        orders={orders}
                        title="Заказы"
                        elementFilter={(
                            <Segment className="order-filter-segment">
                                <Checkbox
                                    className="filter-item-checkbox"
                                    toggle
                                    label="Показывать завершенные"
                                    defaultChecked={showCompleted}
                                    onChange={() => setShowCompleted(!showCompleted)}
                                />
                            </Segment>
                        )}
                        {...props}
                    />
              )}
              mustAuth={true}
              isAuth={!!token}
              isLoading={loading}
              loaderText="Загружаем заказы ..."
              zeroText="Заказы отсутствуют"
          />
        </>
  );

}

