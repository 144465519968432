import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {
    Card, Divider, Header, Icon, Loader, Button,
    Segment, Modal, Dimmer, Image, Grid,
} from 'semantic-ui-react';

import {getOrders, getPlaceOrderInProgress} from "../../views/Orders/reducer";
import {Link} from "react-router-dom";

import './styles.css';
import {getCountries, isCountriesFetching} from "../AddressCard/reducer";
import {
    fetchCounties,
} from "../AddressCard/actions";
import OrderCardHeader from "./OrderCardHeader";
import {receiveOrderData, updateOrder} from "../../views/Orders/actions";
import {useReactToPrint} from "react-to-print";
import {toastr} from "react-redux-toastr";
import {addProduct} from "../../views/Cart/actions";
import {getCart} from "../../views/Cart/reducer";
import _ from "lodash";

export default function MiniOrderCard(props) {

    const { order, circular, header, actionButtons, dispatch, hideOrderDetails, token, userData, userLocation, mixpanel } = props;
    const { billing, id } = order;

    const getLoading = useSelector((state) => getPlaceOrderInProgress(state.orders));
    const getLoadingCountries = useSelector((state) => isCountriesFetching(state.address));
    const countries = useSelector((state) => getCountries(state.address));
    const cart = useSelector((state) => getCart(state.cart));
    const orders =  useSelector( (state) => getOrders(state.orders));

    const country = typeof countries === 'object' && typeof countries.countries === 'object' && typeof billing === 'object'
        ? countries.countries[billing.country] ?? {} : {};
    const countryStates = typeof country === 'object' && country.states
        ? country.states.filter( item => String(item.code) === billing.state ) : [];

    useEffect(() => {
        if (!countries && !getLoadingCountries) {
            console.warn('Mini order card has queried countries ...');
            dispatch(fetchCounties());
        }
        dispatch(receiveOrderData([]));
    }, []);

    const orderCountry = country.iso ? country.emoji + ' ' + country.country_ru : (
        billing.country ? billing.country : 'RU' );
    const orderState = countryStates.length ? countryStates[0].name : `регион ${billing.state}`;
    const orderAddress = billing.city === billing.address_1 ? '' : billing.address_1;

    const order_in_cart = !!cart.find(obj => obj.id === id);
    const src = order.file_image ?? "https://react.semantic-ui.com/images/wireframe/square-image.png";

    const OrderImage = () => (
        <Image src={src}
               size="medium"
               circular={ order.file_image ? false : circular}
               className="order-image"
        />
    );

    function addItem() {
        if (!_.isEmpty(cart)) {
            // проверяем на принадлежность отвеса к одному заказу покупателя и продукту
            const fromCartOrder = orders.find((obj) => Number(obj.id) === Number(cart[0].id),);
            console.log('order from cart', fromCartOrder, order);

            if (!fromCartOrder ||
                order.source_customer_order_id !== fromCartOrder.source_customer_order_id ||
                order.source_plant_order_id !== fromCartOrder.source_plant_order_id ||
                order.sku_type !== fromCartOrder.sku_type ||
                order.source_product_id !== fromCartOrder.source_product_id) {
                toastr.error('Отвес не может быть добавлен в корзину, потому что он не совместим с ранее добавленными!');
                return;
            }
        }

        dispatch(
            addProduct(id,
                {
                    id,
                    src,
                    order_number: order.order_number,
                    order_plant_date: order.order_plant_date,
                    seq_plant_number: order.seq_plant_number,
                    seq_order_number: order.seq_order_number,
                    sku_type: order.sku_type,
                    source_product_name: order.source_product_name,
                    spec_name: order.spec_name,
                    source_customer_order_id: order.source_customer_order_id,
                    source_plant_order_id: order.source_plant_order_id,
                }
            ),
        );
        toastr.success('Добавление во взвешивание', order.order_number + ' добавлен в корзину');
    }

    let componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <div>
            <Card centered color="blue" raised>
                { header ?
                    <div>
                        <OrderCardHeader order={order} {...props} actionButtons={false}/>
                        <Dimmer active={ [1, id].includes(getLoading) } inverted>
                            <Loader inverted />
                        </Dimmer>
                    </div>
                 : ''}
                <Card.Content>
                    <Grid>
                        <Grid.Column className="order-mini-card-header-first-col">
                            <OrderImage />
                        </Grid.Column>
                        <Grid.Column className="order-mini-card-header-second-col">
                            <Link to={'/orders/' + id} >
                                <Card.Header as="h4" className="order-header-fio-col">
                                    {`Заказ покупателя № ${order.source_customer_order_id}`}
                                    <br/>
                                    {'Продукт "' + order.source_product_name + '"'}
                                    <br/>
                                    {'Спецификация "' + order.spec_name + '"'}
                                    <br/>
                                    <br/>
                                    {hideOrderDetails ? ''
                                        : 'отвес № ' + order.seq_plant_number + ' ' +
                                        (order.sku_type === 'minerals' ? 'МИНЕРАЛЫ' : 'ВИТАМИНЫ')
                                    }
                                    <br/>
                                    <br/>
                                    {billing.company}
                                    <br/>
                                    {billing.last_name + ' ' + billing.first_name}

                                </Card.Header>
                            </Link>
                        </Grid.Column>
                    </Grid>
                    <Divider/>
                    <div className="order-mini-card-country" size="small" dangerouslySetInnerHTML={{ __html: `${orderCountry}, ${orderState}` }} />
                    <div  className="order-mini-card-city" size="small" dangerouslySetInnerHTML={{ __html: `${billing.city}` }} />

                </Card.Content>
                { actionButtons ?
                    <Card.Content extra>
                        <div className="order-card-action-buttons">
                            <Link to={'/orders/' + id} >
                                <Button primary size="big" color="orange">
                                    Начать взвешивание
                                </Button>
                            </Link>
                            { order_in_cart ?
                                <Link to="/cart">
                                    <Button color="green" size="big" className="product-card-button-goto-cart">
                                        Перейти на платформу &nbsp;
                                    </Button>
                                </Link> :
                                <Button secondary size="big"  onClick={addItem}>
                                    Добавить на платформу &nbsp;
                                </Button>
                            }
                        </div>
                    </Card.Content> : '' }
            </Card>

        </div>
        );
}