import React, { useEffect} from 'react';
import {useSelector} from 'react-redux';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import config from '../../config/config';
import { Segment, Header, Button } from 'semantic-ui-react';
import {getCart} from "../../views/Cart/reducer";
import {getOrders} from "../../views/Orders/reducer";
import OrderCardHeader from "../OrderCard/OrderCardHeader";
import MiniOrderCard from "../OrderCard/MiniOrderCard";
import OrderProductHeader from "../OrderCard/OrderProductHeader";
import OrderProduct from "../OrderCard/OrderProduct";


export default function MultiScale(props) {

  const {userLocation, userData, mixpanel, dispatch} = props;
  const cart = useSelector((state) => getCart(state.cart));
  if (_.isEmpty(cart))
      return <Segment textAlign="center">Вы не выбрали заказы на взвешивание</Segment>;

  const orders =  useSelector( (state) => getOrders(state.orders));

  const totalLineItems = [];
  cart.forEach((item) => {
      const order = orders.find((obj) => Number(obj.id) === Number(item.id),);
      order.line_items.forEach((order_line_item) => {
          if (!(order_line_item.sku in totalLineItems))
              totalLineItems[order_line_item.sku] = [];
          totalLineItems[order_line_item.sku].push({ ...order_line_item, order_id: Number(order.id) });
      })
  });

  console.log('totalLineItems', totalLineItems);

  useEffect(() => {
    if (mixpanel)
      mixpanel.track('Multi Scale View', { ...userLocation, ...userData, cart });
    window.scrollTo(0, 0);
  }, []);

  const anyOrder = orders.find((obj) => Number(obj.id) === Number(cart[0].id),);

  const panels = [];
  let index = 0
  for (let skuCode in totalLineItems) {
            // key: the name of the object key
            // index: the ordinal position of the key within the object
            const lineItem = totalLineItems[skuCode][0];

            panels.push(
                <OrderProduct
                    key={lineItem.id}
                    product={{
                        id: lineItem.id,
                        sku: lineItem.sku,
                        order: anyOrder,
                        order_item_index: index,
                        quantity: lineItem.quantity,
                        scaled: (lineItem.scaled ?? 0),
                        image: lineItem.image_src,
                        name: lineItem.name,
                        short_description: lineItem.product_description,
                        //mixpanel,
                        dispatch,
                        userData,
                        userLocation,
                        multiScale: true,
                        multiData: totalLineItems[skuCode],
                    }}
                    show_buttons={true}
                    {...props}
                />
            );
            index ++;
    }

  console.log('multi scale props', props, anyOrder);

  return (
      <div>
          <Header textAlign="center">Взвешивание</Header>
          <MiniOrderCard
              key={anyOrder.id}
              order={anyOrder}
              header={false}
              circular={true}
              actionButtons={false}
              hideOrderDetails={true}
              {...props}
          />
          <OrderProductHeader
              name="НАИМЕНОВАНИЯ КОМПОНЕНТА"
              quantity="ВЕС ПЛАН"
              total="ВЕС ФАКТ"
              idOrder={anyOrder.id}
          />
          {panels}
      </div>
    );
}
