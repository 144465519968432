import React, {useEffect, useState} from 'react'
import {
    Button,
    Checkbox,
    Header,
    Modal,
    ModalActions,
    Grid,
    Divider,
    Card,
    FormField,
    Form,
    Icon, Input, Label, MessageHeader, MessageContent, Message, Segment
} from 'semantic-ui-react'
import './styles.css';
import _, {forEach} from 'lodash';
import {toastr} from "react-redux-toastr";
import CircularImage from "../CircularImage";
import {WEIGHT_DEVIATION} from "../../config/config";
import {useSelector} from "react-redux";
import {getSerials} from "./reducer";
import setScaleState from "./actions";
import {getOrders} from "../../views/Orders/reducer";

export default function ModalScale (props) {

    const {open, handleOpen, handleClose, callback, mixpanel, userData, userLocation, product, dispatch} = props;

    const serials = useSelector((state) => getSerials(state.serials));
    const orders =  useSelector( (state) => getOrders(state.orders));

    const [state, setState] = useState({
        scale: {
            stable: true,
            overflow: false,
            scale: 0,
            portIndex: ''
        },
    });

    const [scaleMode, setScaleMode] = useState({
        mode: '',
        code: '',
        errorMessage: '',
        orderId: 0,
        scaleValue: 0,
        isScaled: false,
    });

    const order = scaleMode.orderId === 0
        ? product.order
        : orders.find((obj) => Number(obj.id) === Number(scaleMode.orderId),);

    const portSmall = serials.portSmall;
    const portLarge = serials.portLarge;

    let codeInput = "";
    function handleKeyPress(e) {
        console.log('key press handle', e, scaleMode);
        if (scaleMode.mode === 'check') {
            if (e.keyCode === 13) {
                //console.log('scanned product value from key press', codeInput);
                detectProductQRCode(codeInput);
                codeInput = "";
            } else {
                codeInput += e.key;
                //console.log('added key press', e);
            }
        } else {
            if ( scaleMode.mode === "select" && product.multiScale) {
                if (e.keyCode === 13) {
                    console.log('scanned order value from key press', codeInput);
                    detectOrderQRCode(codeInput);
                    codeInput = "";
                } else {
                    codeInput += e.key;
                }
            }
        }
    }

    function detectOrderQRCode(qrValue) {

        if (product.multiData) {

            let values = qrValue.split('"', 2);

            if (values.length !== 2)
                values = qrValue.split('Э', 2);

            if (values.length !== 2)
                values = qrValue.split('@', 2);

            if (values.length !== 2) {
                toastr.error('Ошибка распознавания QR кода, его значение: ' + qrValue);
                return;
            }

            const value = values[1];
            const data = product.multiData.find((obj) => String(obj.order_id) === value);

            console.log('detectOrderQRCode', data, qrValue, value)

            if (!data) {
                toastr.error('Нет такого номера отвеса в текущем взвешивании! QR код содержит: ' + qrValue);
                return;
            }

            if (Number(data.scaled) > 0) {
                toastr.error('Компонент уже был добавлен в этот отвес, повторное взвешивание в него не допускается');
                return;
            }

            setScaleMode({
                ...scaleMode,
                mode: "weight",
                orderId: data.order_id,
                scaleValue: data.quantity,
                isScaled: false,
            });

            const portIndex = getPortIndexByWeight(data.quantity);

            if (serials[portIndex].isSet && !serials[portIndex].isOpen)
                runSerial(portIndex);
        }
    }

    function detectProductQRCode(value) {

        console.log('detectProductQRCode scanned value = ', value);

        // {"SKU":"017","MFN":"vitamin-a","EXP":"13.08.2024","SEQ":"2"}
        // ХЭЫЛГЭЖЭ017ЭбЭЬАТЭЖЭмшефьшт-фЭбЭУЧЗЭЖЭ13ю08ю2024ЭбЭЫУЙЭЖЭ2ЭЪ

        value = value.replace(/["Ээ@]/g, '"');
        value = value.replace(/[Хх]/g, '{');
        value = value.replace(/[Ъъ]/g, '}');
        value = value.replace(/[Жж]/g, ':');
        value = value.replace(/[Ыы]/g, 'S');
        value = value.replace(/[Лл]/g, 'K');
        value = value.replace(/[Гг]/g, 'U');
        value = value.replace(/[Уу]/g, 'E');
        value = value.replace(/[Чч]/g, 'X');
        value = value.replace(/[Зз]/g, 'P');
        value = value.replace(/[Ьь]/g, 'M');
        value = value.replace(/[Аа]/g, 'F');
        value = value.replace(/[Тт]/g, 'N');
        value = value.replace(/[Йй]/g, 'Q');
        value = value.replace(/[Юю]/g, '.');
        value = value.replace(/[Бб]/g, ',');

        console.log('detectProductQRCode replaced value = ', value);

        const today = new Date(),
            dd = String(today.getDate()).padStart(2, '0'),
            mm = String(today.getMonth() + 1).padStart(2, '0'), //January is 0!
            yyyy = today.getFullYear(),
            todayDateStr = yyyy + '-' + mm + '-' + dd;

        let expDate = todayDateStr;

        try {
            const obj = JSON.parse(value);
            console.log('СКУ в формате JSON', obj);
            value = obj.SKU ?? obj.sku ?? obj;
            expDate = obj.EXP ?? obj.exp ?? todayDateStr;
            expDate = expDate.slice(6, 10) + '-' + expDate.slice(3, 5) + '-' + expDate.slice(0,2);

        } catch (e) {
            console.log('СКУ в формате STRING', e);
            value = value.replace(/["Ээ@]/g, '');
        }

        console.log('detectProductQRCode sku, expired = ', value, expDate);

        if (value === product.sku) {

            if (expDate < todayDateStr) {

                const errorMessage = 'Компонент с истекшим сроком годности, QR код содержит значение: ' + expDate;
                setScaleMode({...scaleMode, errorMessage });
                if (mixpanel)
                    mixpanel.track('Scale Modal SKU Error', { ...userLocation, ...userData, ...product, value, expDate, errorMessage });

            } else {

                if (product.multiScale) {

                    setScaleMode({
                        ...scaleMode,
                        code: value,
                        mode: "select",
                        errorMessage: "",
                    });

                } else {

                    setScaleMode({
                        ...scaleMode,
                        mode: 'weight',
                        code: value,
                        errorMessage: '',
                        orderId: product.order.id,
                        scaleValue: product.quantity,
                        isScaled: false,
                    });

                    const portIndex = getPortIndexByWeight(product.quantity);

                    if (serials[portIndex].isSet && !serials[portIndex].isOpen)
                        runSerial(portIndex);

                    if (mixpanel)
                        mixpanel.track('Scale Modal SKU Success', {
                            ...userLocation, ...userData, ...product, value, portIndex
                        });
                }
            }

        } else {

            const errorMessage = 'Неправильно выбран компонент, QR код содержит значение: ' + value;
            setScaleMode({...scaleMode, errorMessage });
            if (mixpanel)
                mixpanel.track('Scale Modal SKU Error', { ...userLocation, ...userData, ...product, value, errorMessage });
        }

        const input = document.querySelector(".input-product-qr-code input");
        input.value = "";
    }

    useEffect(() => {

        setScaleMode({
            ...scaleMode,
            mode: 'check',
            code: '',
            errorMessage: '',
            isScaled: false,
        });

        if (mixpanel)
            mixpanel.track('Scale Modal Did Mount', { ...userLocation, ...userData, ...product});

        initSerial();

    }, []);

    useEffect(() => {
        console.log('use effect from scaleMode.mode');
        // subscribe event
        document.addEventListener("keypress", handleKeyPress);
        return () => {
            // unsubscribe event
            document.removeEventListener("keypress", handleKeyPress);
        };
    }, [scaleMode.mode]);

    function setPortSmall(data) {
        dispatch(setScaleState('portSmall', data));
    }

    function setPortLarge(data) {
        dispatch(setScaleState('portLarge', data));
    }

    function getPortIndexByWeight(weight) {
        let portIndex = 'portSmall';

        if (portSmall.maxWeight < weight * (1 + WEIGHT_DEVIATION / 100))
            portIndex = 'portLarge';

        return portIndex;
    }

    const scaleEquals =
        (state.scale.scale >= scaleMode.scaleValue && state.scale.scale <= scaleMode.scaleValue * (1 + WEIGHT_DEVIATION / 100))
        && !state.scale.overflow
        && state.scale.stable;
    const scaleEqualsClass =
        (state.scale.scale === 0 ? '' : (scaleEquals ? ' scale-equals' : ' scale-not-equals')) +
        (state.scale.overflow ? ' overflow' : '');

    async function prepareSerial(ports, portIndex, index) {
        if (serials[portIndex].isSet) {
            if (typeof serials[portIndex].serial.readable !== 'undefined') {
                if (serials[portIndex].debugMode)
                    console.log(portIndex + ' уже установлен и не слетел, останавливаем чтение из него');
                await stopSerial(portIndex);
            } else {
                if (ports.length > index) {
                    try {
                        await ports[index].open({baudRate: serials[portIndex].baudRate});
                        await ports[index].close();
                        dispatch(setScaleState(portIndex,
                            {
                                        ...serials[portIndex],
                                        serial: ports[index],
                                        isSerialAvailable: true,
                                        isSet: true,
                                        info: ports[index].getInfo()
                                    }));
                        if (serials[portIndex].debugMode)
                            console.log(portIndex + ' был установлен, но слетел, переприсвоили его из ранее разрешенных', ports[index]);
                    } catch (error) {
                        if (serials[portIndex].debugMode)
                            console.log(portIndex + ' был установлен, но слетел, при переприсвоении из разрешенных возникла ошибка', error);
                        dispatch(setScaleState(portIndex,
                            {
                                        ...serials[portIndex],
                                        isSerialAvailable: true,
                                        isSet: false,
                                    }));
                    }
                }
            }
        }
    }

    async function initSerial() {
        const isSerialAvailable = ("serial" in navigator);
        const debugMode = portSmall.debugMode || portLarge.debugMode;

        if (isSerialAvailable) {
            if (debugMode)
                console.log('init Serial from useEffect', portSmall, portLarge);

            // проверяем разрешение на используемые порты
            let ports;
            try {
                // запрашиваем спаренные порты,
                // с индексом 1 - маленькие весы на COM1 ?
                // с индексом 0 - большие весы на COM2 ?
                ports = await navigator.serial.getPorts();
                if (debugMode)
                    console.log('navigator.serial.getPorts', ports);
            } catch (error) {
                console.error('Serial ports access error.', error);
                setPortSmall({ ...portSmall, isSerialAvailable: true, isSet: false });
                setPortLarge({ ...portLarge, isSerialAvailable: true, isSet: false });
                return;
            }

            _.forEach(['portSmall', 'portLarge'], (portIndex, index) => {
                prepareSerial(ports, portIndex, index);
            });

        } else {
            setPortSmall({...portSmall, isSerialAvailable: false});
            setPortLarge({...portLarge, isSerialAvailable: false});
        }

    }

    const handleSave = () => {

        callback(order, scaleMode.code, state);

        if (mixpanel)
            mixpanel.track('Scale Modal Save', { ...userLocation, ...userData, order_id: order.id, sku: scaleMode.code, scale: state.scale.scale });

        setScaleMode({
            ...scaleMode,
            mode: product.multiScale ? "select" : "check",
            orderId: 0,
            scaleValue: 0,
            isScaled: true,
        });

        setState({scale: {...state.scale, scale: 0, portIndex: ''}});

        const unScaledSku = product.multiScale ? product.multiData.filter((obj) => Number(obj.scaled) === 0).length : 0;

        toastr.success('Результат успешно сохранен.' +
            (product.multiScale ?
                    (unScaledSku > 1 ? ' Выберите следующий отвес' : '') : '')
        );

        if (state.scale.portIndex && state.scale.portIndex.length && serials[state.scale.portIndex].isOpen)
            stopSerial(state.scale.portIndex);

        if (!product.multiScale || unScaledSku === 1)
            closeModal();

    };

    const handleCancel = () => {
        closeModal();
        if (mixpanel)
            mixpanel.track('Scale Modal Cancel', { ...userLocation, ...userData, ...product });
    };

    async function setSerial(scaleIndex) {
        if ("serial" in navigator) {

            let port, portInfo, baudRate;
            const debugMode = serials[scaleIndex].debugMode;

            baudRate = (scaleIndex === 'portSmall' ? portSmall.baudRate : portLarge.baudRate);
            // try to select
            try {
                port = await navigator.serial.requestPort();
                const { usbProductId, usbVendorId } = port.getInfo();
                if (debugMode)
                    console.log('usbProductId', usbProductId, usbVendorId);
                portInfo = port.getInfo();
                if (debugMode)
                    console.log('requested Serial Port', port, portInfo);
            } catch (error) {
                console.error('Serial port access error', error);
                toastr.error("Ошибка получения списка портов. " + error);
                return;
            }

            // try to close
            try {
                if (port.readable) {
                    const reader = port.readable.getReader();
                    reader.releaseLock();
                    await port.close();
                }
            } catch (error) {
                if (debugMode)
                    console.log("Ошибка закрытия порта для проверки. " + error);
            }

            // try to open
            try {
                await port.open({baudRate: baudRate});
                await port.close();
                toastr.success("Порт подключен и готов к измерениям");
            } catch (error) {
                toastr.error("Ошибка открытия порта. " + error);
                console.error("Ошибка открытия порта. " + error);
                return;
            }

            // save to redux global state
            dispatch(
                setScaleState(
                    scaleIndex,
           { ...serials[scaleIndex], isSerialAvailable: true, isSet: true, serial: port, info: portInfo }
                ));

        } else {
            toastr.error(`Взвешивание не возможно, т.к. WEB Serial API не поддерживаются текущим браузером!`);
            console.error(`Взвешивание не возможно, т.к. WEB Serial API не поддерживаются текущим браузером!`);
        }
    }

    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    async function toggleSerial(portIndex) {
        if ("serial" in navigator) {
            if ( portIndex === 'portSmall' ? portSmall.isOpen : portLarge.isOpen )
                await stopSerial(portIndex);
            else
                await runSerial(portIndex);
        }
    }

    async function stopSerial(portIndex) {
        if ("serial" in navigator) {

            const port = (portIndex === 'portSmall' ? portSmall.serial : portLarge.serial);
            const portReader = (portIndex === 'portSmall' ? portSmall.reader : portLarge.reader);
            const debugMode = (portIndex === 'portSmall' ? portSmall.debugMode : portLarge.debugMode);
            const isOpen = (portIndex === 'portSmall' ? portSmall.isOpen : portLarge.isOpen);

            if (isOpen) {
                // порт был открыт на чтение, тогда останавливаем поток и закрываем порт
                try {
                    portReader.releaseLock();
                    port.close();
                } catch (error) {
                    toastr.error('Ошибка остановки чтения из порта. ', error);
                    if (debugMode)
                        console.error('Ошибка остановки чтения из порта. ', error);
                }

                dispatch(setScaleState(portIndex, { ...serials[portIndex], isOpen: false, reader: {}, }));

                setState({
                    ...state,
                    scale: {
                        stable: true,
                        overflow: false,
                        scale: 0,
                    },
                    portIndex: ''
                });
            }
        }
    }

    async function runSerial(portIndex) {
        if ("serial" in navigator) {

            const port = (portIndex === 'portSmall' ? portSmall.serial : portLarge.serial);
            const portReader = (portIndex === 'portSmall' ? portSmall.reader : portLarge.reader);
            const baudRate = (portIndex === 'portSmall' ? portSmall.baudRate : portLarge.baudRate);
            const debugMode = (portIndex === 'portSmall' ? portSmall.debugMode : portLarge.debugMode);
            const isOpen = (portIndex === 'portSmall' ? portSmall.isOpen : portLarge.isOpen);

            // порт был закрыт, вызываем открытие и начало чтения/обработки данных
            try {
                await port.open({baudRate: baudRate});
                if (debugMode)
                    console.log('Port open and ready ' + portIndex, port);
            } catch (error) {
                toastr.error('Ошибка открытия порта. ', error);
                if (debugMode)
                    console.error('Ошибка открытия порта. ', error);
                // порт не смогли открыть, выходим
                return;
            }

            dispatch(setScaleState(portIndex, {...serials[portIndex], isOpen: true}))

            let internalState = {
                mode: '',
                data: [],
            }

            internalState.mode = portIndex === 'portSmall' ? portSmall.protocol : portLarge.protocol;

            // start listen port, waiting for ACK 0x06
            while (port.readable) {
                const reader = port.readable.getReader();

                if (portIndex === 'portSmall')
                    setPortSmall({...portSmall, isOpen: true, reader: reader});
                else
                    setPortLarge({...portLarge, isOpen: true, reader: reader});

                try {
                    while (true) {

                        if (!port.readable) {
                            if (debugMode)
                                console.log('Port yet not readable, exit ' + portIndex,
                                    portIndex === 'portSmall' ? portSmall : portLarge);
                            break;
                        }

                        if (internalState.mode === 'ENQ' || internalState.mode === 'DC1') {
                            // CAS DC1-ENQ протокол
                            // send ENQ code to port
                            if (port.writable) {
                                const writer = port.writable.getWriter();
                                const data = new Uint8Array([
                                    internalState.mode === 'ENQ' ? 5 : 17   // ENQ 0x05 or DC1 0x11
                                ]);
                                await writer.write(data);
                                writer.releaseLock();
                                if (debugMode)
                                    console.log('Write to port data ' + portIndex,
                                        portIndex === 'portSmall' ? portSmall : portLarge,
                                        internalState.mode, data);
                            }
                        }

                        // waiting for answer
                        const {value, done} = await reader.read();
                        if (done) {
                            // |reader| has been canceled.
                            break;
                        }

                        if (internalState.mode === 'ENQ') {
                            if (debugMode)
                                console.log('Received data from serial ' + portIndex,
                                    portIndex === 'portSmall' ? portSmall : portLarge,
                                    internalState.mode, value, done);

                            if (value[0] === 6) {
                                // send DC1 command to port
                                internalState.mode = 'DC1';
                                internalState.data = [];
                                continue;
                            }

                            await sleep(200);
                        }

                        if (internalState.mode === 'DC1') {
                            // parse weight value and read again
                            internalState.data.push(...value);
                            // 1, 2, 83, 32, 32, 48, 46, 50, 52, 54, 107, 103, 113, 3, 4
                            // 1, 2, 83, 32, 32, 48, 46, 51, 51, 56, 107, 103, 121, 3, 4
                            if (debugMode)
                                console.log('Received data from serial ' + portIndex,
                                    portIndex === 'portSmall' ? portSmall : portLarge,
                                    internalState, value, done);

                            if (internalState.data.length === 15) {
                                // all be received, parse it
                                const scaleDataValue = (internalState.data[3] === 45 ? -1 : 1) * (
                                    (internalState.data[4] === 32 ? 0 : internalState.data[4] - 48) * 10000 +
                                    (internalState.data[5] - 48) * 1000 +
                                    (internalState.data[7] - 48) * 100 +
                                    (internalState.data[8] - 48) * 10 +
                                    (internalState.data[9] - 48)
                                );

                                const scale = {
                                    stable: internalState.data[2] === 83,
                                    overflow: internalState.data[3] === 70,
                                    scale: scaleDataValue,
                                    portIndex: portIndex,
                                };

                                if (Number.isInteger(scaleDataValue)) {

                                    setState({...state, scale: scale, portIndex: portIndex,});
                                }

                                if (debugMode)
                                    console.log('SCALED!', scale)

                                // sleep and send ENQ again in loop
                                internalState.mode = 'ENQ';
                                await sleep(200);
                            }
                        }

                        if (internalState.mode === 'INF') {
                            // parse weight value and read again
                            internalState.data.push(...value);
                            // 32, 32, 49, 52, 46, 54, 50, 48, 107, 103, 13, 10

                            if (debugMode)
                                console.log('Received data from serial ' + portIndex,
                                    portIndex === 'portSmall' ? portSmall : portLarge,
                                    internalState, value, done);

                            if (internalState.data.length >= 40) {

                                let i = internalState.data.length - 1, j = 0, k = 0;
                                while (i > 0) {
                                    if (internalState.data[i] === 10 && internalState.data[i - 1] === 13) {
                                        if (j === 0 && k > 0)
                                            j = i + 1;
                                        if (k === 0)
                                            k = i + 1;
                                    }
                                    i--;
                                }
                                let scaleValues = internalState.data.slice(j, k);
                                internalState.data = internalState.data.slice(k);

                                if (debugMode)
                                    console.log('j, k', j, k, scaleValues, internalState.data);

                                const scaleDataValue =
                                    (scaleValues[0] === 45 ? -1 : 1) * (
                                    (scaleValues[1] === 32 ? 0 : scaleValues[1] - 48) * 100000 +
                                    (scaleValues[2] === 32 ? 0 : scaleValues[2] - 48) * 10000 +
                                    (scaleValues[3] === 32 ? 0 : scaleValues[3] - 48) * 1000 +
                                    (scaleValues[5] === 32 ? 0 : scaleValues[5] - 48) * 100 +
                                    (scaleValues[6] === 32 ? 0 : scaleValues[6] - 48) * 10 +
                                    (scaleValues[7] === 32 ? 0 : scaleValues[7] - 48)
                                );

                                const scale = {
                                    stable: true, //scaleValues[0] === 83 && scaleValues[1] === 84,
                                    overflow: false, //scaleValues[0] === 79 && scaleValues[1] === 76,
                                    scale: scaleDataValue,
                                    portIndex: portIndex,
                                };

                                if (Number.isInteger(scaleDataValue)) {
                                    if (debugMode)
                                        console.log('SCALED!', scaleValues, scale)
                                    //if (Number(scaleDataValue) !== Number(state.scale.scale)) {
                                        setState({...state, scale: scale, portIndex: portIndex,});
                                        if (debugMode)
                                            console.log('New weight saved', scaleValues)
                                   // }
                                    //internalState.data = [];
                                }

                                await sleep(200);
                            }

                        }

                    }
                } catch (error) {
                    if (debugMode)
                        console.error('Ошибка чтения из порта. ' + error);

                } finally {
                    reader.releaseLock();
                    if (portIndex === 'portSmall')
                        setPortSmall({...portSmall, isOpen: false});
                    else
                        setPortLarge({...portLarge, isOpen: false});
                    setState({
                        ...state,
                        scale: {
                            stable: true,
                            overflow: false,
                            scale: 0,
                            portIndex: portIndex,
                        },
                        reader: {}
                    });
                }
            }

        } else {
            toastr.error(`Взвешивание не возможно, WEB Serial API не поддерживаются текущим браузером!`);
            console.error(`Взвешивание не возможно, WEB Serial API не поддерживаются текущим браузером!`);
        }
    }

    function savePortBaudRate(value, scaleIndex) {
        dispatch(setScaleState(scaleIndex, {...serials[scaleIndex], baudRate: Number(value)}));
    }

    function savePortMaxWeight(value, scaleIndex) {
        dispatch(setScaleState(scaleIndex, {...serials[scaleIndex], maxWeight: Number(value)}));
    }

    function closeModal() {
        stopSerial('portSmall');
        stopSerial('portLarge');
        handleClose();
    }

    function getProductSelections() {
        if (_.isNil(product.selections)) {
            return null;
        }

        const description = Object.keys(product.selections)
            .map((key) => _.startCase(key) + ': ' + product.selections[key])
            .join(', ');

        return (
            <Grid.Row>
                <Grid.Column width={16}>{description}</Grid.Column>
            </Grid.Row>
        );
    }

    function portSettings() {
        return (
            <>
                <Message icon color={portSmall.isSet ? (portSmall.isOpen ? 'green' : 'yellow') : 'red'}>
                    <Icon name="balance scale"  size="small"/>
                    <MessageContent>
                        <MessageHeader>Весы малые</MessageHeader>
                        <div className="scale-setting-wrapper">
                            <Checkbox
                                label='показать настройки'
                                onChange={
                                    (e, data) =>
                                        setPortSmall({...portSmall, showSettings:data.checked})
                                }
                                checked={portSmall.showSettings}
                                disabled={portLarge.isOpen || portSmall.isOpen}
                            />
                            { portSmall.showSettings ?
                                <Segment raised className="port-settings-segment">
                                    <Button
                                        onClick={() => setSerial('portSmall')}
                                        disabled={portSmall.isOpen || portLarge.isOpen}
                                    >
                                        Выбрать порт
                                    </Button>
                                    <Button
                                        onClick={() => setPortSmall({
                                            ...portSmall,
                                            protocol: (portSmall.protocol === 'ENQ' ? 'INF' : 'ENQ')
                                        })}
                                        disabled={portSmall.isOpen || portLarge.isOpen}
                                    >
                                        { portSmall.protocol === 'ENQ' ? 'Передача по запросу' : 'Постоянная передача'}
                                    </Button>
                                    <Input className="scale-input"
                                           label="Скорость" type="number" min="0"
                                           value={portSmall.baudRate ?? ''}
                                           onChange={(e) =>
                                               savePortBaudRate(e.target.value, 'portSmall')}
                                           disabled={!portSmall.isSet || portSmall.isOpen || portLarge.isOpen}
                                    />
                                    <Input className="scale-input"
                                           label="Макс. вес" type="number" min="0"
                                           value={portSmall.maxWeight ?? 0}
                                           onChange={(e) =>
                                               savePortMaxWeight(e.target.value, 'portSmall')}
                                           disabled={!portSmall.isSet || portSmall.isOpen || portLarge.isOpen}
                                    />
                                    <Checkbox
                                        label='включить инженерный режим'
                                        onChange={
                                        (e, data) =>
                                            setPortSmall({...portSmall, debugMode:data.checked})
                                        }
                                        checked={portSmall.debugMode}
                                        disabled={portSmall.isOpen || portLarge.isOpen}
                                   />
                            </Segment> : '' }
                        </div>
                    </MessageContent>
                    <Button
                        primary
                        icon
                        disabled={!portSmall.isSet || portLarge.isOpen}
                        onClick={() => toggleSerial('portSmall')}
                    >
                        <Icon name={portSmall.isOpen ? "pause" : "play"}/>
                    </Button>
                    <Label className="weight-status-label"
                           color={portSmall.isSet ? (portSmall.isOpen ? 'green' : 'yellow') : 'red'}>
                        {portSmall.isSet ? (portSmall.isOpen ? 'взвеш.' : 'пауза') : 'не подключено'}
                    </Label>
                </Message>

                <Message icon color={portLarge.isSet ? (portLarge.isOpen ? 'green' : 'yellow') : 'red'}>
                    <Icon name="balance scale" size="large"/>
                    <MessageContent>
                        <MessageHeader>Весы большие</MessageHeader>
                        <div className="scale-setting-wrapper">
                            <Checkbox
                                label='показать настройки'
                                onChange={
                                    (e, data) =>
                                        setPortLarge({...portLarge, showSettings:data.checked})
                                }
                                checked={portLarge.showSettings}
                                disabled={portLarge.isOpen || portSmall.isOpen}
                            />
                            { portLarge.showSettings ?
                                <Segment raised className="port-settings-segment">
                                    <Button
                                        onClick={() => setSerial('portLarge')}
                                        disabled={portLarge.isOpen || portSmall.isOpen}
                                    >
                                        Выбрать порт
                                    </Button>
                                    <Button
                                        onClick={() => setPortLarge({
                                            ...portLarge,
                                            protocol: (portLarge.protocol === 'ENQ' ? 'INF' : 'ENQ')
                                        })}
                                        disabled={portSmall.isOpen || portLarge.isOpen}
                                    >
                                        { portLarge.protocol === 'ENQ' ? 'Передача по запросу' : 'Постоянная передача'}
                                    </Button>
                                    <Input className="scale-input"
                                           label="Скорость" type="number" min="0"
                                           value={portLarge.baudRate ?? ''}
                                           onChange={(e) =>
                                               savePortBaudRate(e.target.value, 'portLarge')}
                                           disabled={!portLarge.isSet || portLarge.isOpen || portSmall.isOpen}
                                    />
                                    <Input className="scale-input"
                                           label="Макс. вес" type="number" min="0"
                                           value={portLarge.maxWeight ?? 0}
                                           onChange={(e) =>
                                               savePortMaxWeight(e.target.value, 'portLarge')}
                                           disabled={!portLarge.isSet || portLarge.isOpen || portSmall.isOpen}
                                    />
                                    <Checkbox
                                        label='включить инженерный режим'
                                        onChange={
                                            (e, data) =>
                                                setPortLarge({...portLarge, debugMode:data.checked})
                                        }
                                        checked={portLarge.debugMode}
                                        disabled={portLarge.isOpen || portSmall.isOpen}
                                    />
                                </Segment> : '' }
                        </div>
                    </MessageContent>
                    <Button
                        primary
                        icon
                        disabled={!portLarge.isSet || portSmall.isOpen}
                        onClick={() => toggleSerial('portLarge')}
                    >
                        <Icon name={portLarge.isOpen ? "pause" : "play"}/>
                    </Button>
                    <Label className="weight-status-label"
                           color={portLarge.isSet ? (portLarge.isOpen ? 'green' : 'yellow') : 'red'}>
                        {portLarge.isSet ? (portLarge.isOpen ? 'взвеш.' : 'пауза') : 'не подключено'}
                    </Label>
                </Message>
            </>
        );
    }

    if (portSmall.debugMode || portLarge.debugMode)
        console.log('modal scale props & states', props, scaleMode, state, order, portSmall, portLarge);

    return(
        <Modal
            closeIcon
            dimmer
            centered
            open={open}
            onOpen={handleOpen}
            onClose={closeModal}
            className="modal-scale"
            closeOnDimmerClick={false}
        >
            <Header content={"Взвешивание. " + (product.multiScale ? " Мульти режим" : "Одиночный режим")}/>
            <Modal.Content>
                <Card centered color="teal" raised>
                    <Card.Content>
                        <Grid doubling>
                            <Grid.Row centered>
                                <Grid.Column width={3} textAlign="center" className="first-column">
                                    <CircularImage className="cart-product-image" src={product.image} width={200} circular={false}/>
                                </Grid.Column>
                                <Grid.Column width={5} className="second-column break-words" textAlign="left">
                                    {product.name}
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: product.short_description,
                                        }}
                                    />
                                    { process.env.REACT_APP_ENV === 'development' ? product.sku : ''}
                                </Grid.Column>
                                { order ?
                                    <>
                                        <Divider vertical className="scale-header-divider">{">>"}</Divider>
                                        <Grid.Column width={8} textAlign="center" className="last-column">
                                            <Grid.Row>
                                                {`Заказ покупателя № ${order.source_customer_order_id}`}
                                                <br/>
                                                {order.source_product_name}
                                            </Grid.Row>
                                            <Grid.Row className="order-scale-plant-number">
                                                { scaleMode.orderId
                                                    ? 'отвес № ' + order.seq_plant_number
                                                    : ''
                                                }
                                            </Grid.Row>
                                            <Grid.Row>
                                                {order.billing.company}
                                                <br/>
                                                {order.billing.last_name + ' ' + order.billing.first_name}
                                            </Grid.Row>
                                        </Grid.Column>
                                    </> : <Grid.Column width={8}/> }
                            </Grid.Row>
                            {getProductSelections()}
                        </Grid>

                        <Divider/>

                        {scaleMode.mode === 'check' ?
                            <Form>
                                <FormField>
                                    <Input
                                        focus
                                        placeholder='Отсканируйте QR-код или введите артикул'
                                        icon={<Icon name='search' inverted circular />}
                                        label="Код компонента"
                                        autoFocus={true}
                                        className="input-product-qr-code"
                                    />
                                    { scaleMode.errorMessage !== '' ?
                                        <Label basic color='red' pointing>
                                            {scaleMode.errorMessage}
                                        </Label> : '' }
                                </FormField>
                            </Form> : '' }

                        { scaleMode.mode === 'select' ?
                            <>
                                <Message
                                    icon="law"
                                    header="Выберите отвес для взвешивания"
                                    content="Для выбора отсканируйте QR код на упаковке отвеса"
                                />
                                { process.env.REACT_APP_ENV === 'development' ?
                                    <Input onChange={(e) =>
                                        detectOrderQRCode(e.target.value)} /> : ''
                                }
                            </> : ''
                        }
                        { scaleMode.mode === 'weight' ?
                            <>
                                { portSettings() }
                                <Divider/>
                                { scaleMode.mode === 'weight' ?
                                    <>
                                        <Grid doubling>
                                            <Grid.Row centered className="scale-row-header">
                                                <Grid.Column width={8} textAlign="center" className="first-column">
                                                    ВЕС ПЛАН, кг
                                                </Grid.Column>
                                                <Grid.Column width={8} className="second-column break-words" textAlign="center">
                                                    ВЕС ФАКТ, кг
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row centered>
                                                <Grid.Column width={8} textAlign="center" className="first-column">
                                                    <div className="scale-row-data"
                                                        dangerouslySetInnerHTML={{
                                                            __html: (product.quantity / 1000).toFixed(3),
                                                        }}
                                                    />
                                                </Grid.Column>
                                                <Divider vertical>И</Divider>
                                                <Grid.Column width={8} className="second-column" textAlign="center">
                                                    { process.env.REACT_APP_ENV === 'development' ?
                                                        <Input onChange={(e) =>
                                                            setState({
                                                                ...state,
                                                                scale: {
                                                                    scale: e.target.value,
                                                                    overflow: false,
                                                                    stable: true,
                                                            }})} /> : ''
                                                    }
                                                    <div className={"scale-row-data" + scaleEqualsClass}
                                                        dangerouslySetInnerHTML={{
                                                            __html:
                                                                state.scale.overflow ?
                                                                    'ПЕРЕГРУЗ' : (state.scale.scale / 1000).toFixed(3),
                                                        }}
                                                    />
                                                    {state.scale.stable ? '' :
                                                        <Label className="label-scale-not-stable" color="red">
                                                            вес не стабилен
                                                        </Label>}
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </>
                                    : ''}
                            </> : ''
                        }
                    </Card.Content>
                </Card>
            </Modal.Content>
            <ModalActions>
                <Button className="button-save-scale"
                        positive
                        onClick={handleSave}
                        size = "big"
                        disabled={!scaleEquals || scaleMode.isScaled || scaleMode.mode !== 'weight'}
                >Сохранить</Button>
            </ModalActions>
        </Modal>
    )
}