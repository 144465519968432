import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {
    Card, Divider, Header, Icon, Loader, Button,
    Segment, Modal, Dimmer, Image, Grid, Label, ButtonOr,
} from 'semantic-ui-react';

import {Link} from "react-router-dom";

import './styles.css';
import {getCountries, isCountriesFetching} from "../AddressCard/reducer";
import {
    fetchCounties,
} from "../AddressCard/actions";
import {Base64} from "../../App";

export default function OrderGroupedCard(props) {

    const { order, dispatch } = props;
    const { billing_country, billing_state, billing_address, billing_company, billing_city,
        billing_first_name, billing_last_name, order_status } = order;

    const getLoadingCountries = useSelector((state) => isCountriesFetching(state.address));
    const countries = useSelector((state) => getCountries(state.address));

    const country =
        countries && countries.countries && billing_country.length
        ? countries.countries[billing_country] ?? {} : {};

    const countryStates = country && country.states && billing_state.length
        ? country.states.filter( item => String(item.code) === billing_state ) : [];

    useEffect(() => {
        if (!countries && !getLoadingCountries) {
            console.warn('Group order card has queried countries ...');
            dispatch(fetchCounties());
        }
    }, []);

    const orderCountry = country.iso ? country.emoji + ' ' + country.country_ru : (
        billing_country ? billing_country : 'RU' );
    const orderState = countryStates.length ? countryStates[0].name : `регион ${billing_state}`;
    const orderAddress = billing_city === billing_address ? '' : billing_address;

    let orderLabelText = "не оформлен", labelColor = "red", icon = "";
    switch (order_status) {
        case 'processing':
            orderLabelText = "в работе";
            labelColor = "grey";
            icon = "trash";
            break;
        case 'completed':
            orderLabelText = "завершен";
            labelColor = "green";
            icon = "trash";
            break;
        case 'cancelled':
            orderLabelText = "отменен";
            labelColor = "brown";
            icon = "undo";
            break;
        default:
            break;
    }

    let filterStr =
        "source_plant_order_id='" + order.source_plant_order_id + "' and " +
        "source_product_id='" + order.source_product_id + "' and " +
        "source_customer_order_id='" + order.source_customer_order_id + "' and ";

    const linkMinerals = Base64.encode(
        filterStr + "sku_type='minerals'"
    );

    const linkVitamines = Base64.encode(
        filterStr + "sku_type='vitamins'"
    );


    return (
        <div>
            <Card centered color="blue" raised>
                <Card.Header as="h4" className="grouped-order-header" textAlign="center">
                    <Label as="a" color={labelColor} ribon="" attached="top left">{orderLabelText}</Label>

                    <div className="order-grouped-card-main-text" size="small"
                         dangerouslySetInnerHTML={{
                             __html: order.plant_name
                         }}/>

                    <div className="order-grouped-card-main-text" size="small"
                         dangerouslySetInnerHTML={{
                             __html: `Заказ на производство № ${order.source_plant_order_id} на ${order.order_plant_date}`
                         }}/>

                    <br/>

                    <div className="order-grouped-card-main-text" size="small"
                         dangerouslySetInnerHTML={{
                             __html: 'Продукт "' + order.source_product_name + '"'
                         }}/>

                    <div className="order-grouped-card-main-text" size="small"
                         dangerouslySetInnerHTML={{
                             __html: 'Спецификация "' + order.spec_name + '"'
                         }}/>
                </Card.Header>

                <Card.Content>

                    <div className="order-grouped-card-main-text" size="small"
                         dangerouslySetInnerHTML={{
                             __html: `Заказ покупателя № ${order.source_customer_order_id}`
                         }}/>
                    <div className="order-grouped-card-main-text" size="small"
                         dangerouslySetInnerHTML={{
                             __html: billing_company
                         }}/>
                    <div className="order-grouped-card-main-text" size="small"
                         dangerouslySetInnerHTML={{
                             __html: billing_last_name + ' ' + billing_first_name
                         }}/>

                    <Divider/>

                    <div className="order-mini-card-country" size="small"
                         dangerouslySetInnerHTML={{__html: `${orderCountry}, ${orderState}`}}/>
                    <div className="order-mini-card-city" size="small"
                         dangerouslySetInnerHTML={{__html: `${billing_city}`}}/>
                    <div className="order-mini-card-city" size="small"
                         dangerouslySetInnerHTML={{__html: `${orderAddress}`}}/>

                </Card.Content>
                <Card.Content extra>
                        <Link to={'/orders/?filter=' + linkMinerals}>
                            <Button color="blue" size="big" className="order-grouped-card-button">
                                {`Минералы (${order.total_seqs_minerals})`} &nbsp;
                            </Button>
                        </Link>
                        <Link to={'/orders/?filter=' + linkVitamines}>
                            <Button color="green" size="big" className="order-grouped-card-button">
                                {`Витамины (${order.total_seqs_vitamins})`} &nbsp;
                            </Button>
                        </Link>
                </Card.Content>
            </Card>

        </div>
    );
}