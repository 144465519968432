import { combineReducers } from 'redux';
import {
    REQUEST_DOC, RECEIVE_DOC, RECEIVE_1PAGE_DOC, DOC_PAGE_COUNT,
} from './actions';
import {mergeObjectArrays} from "../../configureStore";

const items = (state = [], action = 0) => {
    switch (action.type) {
        case REQUEST_DOC:
            return state;
        case RECEIVE_DOC:
            return mergeObjectArrays(action.doc, state);
        case RECEIVE_1PAGE_DOC:
            return action.doc;
        default:
            return state;
    }
};

const hasMore = (state = false, action) => {
    switch (action.type) {
        case REQUEST_DOC:
            return true;
        case RECEIVE_DOC:
        case RECEIVE_1PAGE_DOC:
            return action.doc.length >= DOC_PAGE_COUNT;
        default:
            return state;
    }
};

const isFetching = (state = {fetching: false, page: 1}, action) => {
    switch (action.type) {
        case REQUEST_DOC:
            return {fetching: true, page: action.page};
        case RECEIVE_DOC:
        case RECEIVE_1PAGE_DOC:
            return {...state, fetching: false};
        default:
            return state;
    }
};

export const getDoc = (state) => {
    return state.items;
};

export const getDocFetching = (state) => state.isFetching.fetching && state.isFetching.page === 1;
export const getDocHasMore = (state) => state.hasMore;

export default combineReducers({
    items,
    isFetching,
    hasMore,
});