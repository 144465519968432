import React, {useState} from 'react';
import _ from 'lodash';
import {
  Card, Grid, Button, Icon, Input,
} from 'semantic-ui-react';

import './styles.css';

export default function OrderProductHeader(props) {

  const {idOrder, name, quantity, total} = props;

    return (
      <Card centered className="order-product-header" color="blue">
        <Card.Content>
          <Grid doubling>
            <Grid.Row centered key={idOrder}>
              <Grid.Column width={11} className="first-column break-words" textAlign="left">
                {name}
              </Grid.Column>
              <Grid.Column width={2} className="third-column">
                {quantity}
              </Grid.Column>
              <Grid.Column width={2} textAlign="right" className="fourth-column">
                {total}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Card.Content>
      </Card>
    );
}

