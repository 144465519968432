import { combineReducers } from 'redux';
import {
  SET_SCALE_STATE
} from "./actions";
import {SERIAL_DEFAULT_BAUD_RATE} from "../../config/config";

const defaultSerialsState = {
  portSmall: {
    isSerialAvailable: false,
    isSet: false,
    isOpen: false,
    serial: {},
    info: {},
    baudRate: SERIAL_DEFAULT_BAUD_RATE,
    protocol: 'ENQ',
    showSettings: false,
    debugMode: false,
    maxWeight: 5000,
    reader: {}
  },
  portLarge: {
    isSerialAvailable: false,
    isSet: false,
    isOpen: false,
    serial: {},
    info: {},
    baudRate: SERIAL_DEFAULT_BAUD_RATE,
    protocol: 'INF',
    showSettings: false,
    debugMode: false,
    maxWeight: 50000,
    reader: {}
  },
}

const items = (state = defaultSerialsState, action) => {
  switch (action.type) {
    case SET_SCALE_STATE:
      if (action.scaleIndex === 'portSmall') {
        return {...state, portSmall: action.scaleData};
      }
      else {
        if (action.scaleIndex === 'portLarge')
          return {...state, portLarge: action.scaleData};
        else
          return state;
      }
    default:
      return state;
  }
};

export const getSerials = (state) => state.items;

const serials = combineReducers({
  items,
});

export default serials;
