import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { fetchProducts, PRODUCTS_PAGE_COUNT } from './actions';
import { getProductsFetching, getProducts, getProductsHasMore } from './reducer';
import ProductsList from '../../components/ProductsList';
import { useParams } from 'react-router';

import "./style.css"
import InfiniteView from "../../components/InfiniteView";
import { setWindowScrollPosition } from "../../components/WindowDimensions";

export default function Products(props) {

  const {dispatch, userData, userLocation, mixpanel, routeAction, isPageReloaded} = props;

  const {categId} = useParams();
  const loading = useSelector((state) => getProductsFetching(state.products));
  const products = useSelector((state) => getProducts(state.products, categId));
  const hasMore = useSelector((state) => getProductsHasMore(state.products));

  useEffect(() => {
    if ( routeAction === 'PUSH' || isPageReloaded ) {
      readProducts(1);
      setWindowScrollPosition();
    }
    if (mixpanel)
      mixpanel.track('Products List View', {...userLocation, ...userData, categId});
  }, [categId]);

  useEffect(() => {
    if (isPageReloaded) {
      setWindowScrollPosition();
    }
  }, [props]);

  const loadProducts = () => {
      const nextPage = Math.round(products.length / PRODUCTS_PAGE_COUNT) + 1;
      readProducts(nextPage);
  };

  const readProducts = (page) => {
    dispatch(fetchProducts({
      category: categId,
      page,
      order: 'asc',
      orderby: 'id',
      per_page: PRODUCTS_PAGE_COUNT,
    }));
  };

  return (
        <InfiniteView
            dataLength={products.length}
            nextFetch={loadProducts}
            hasMore={hasMore}
            dataList={(
                <ProductsList
                    products={products}
                    {...props}
                />
            )}
            mustAuth={false}
            isLoading={loading}
            loaderText="Загружаем продукты ..."
            zeroText="Продукты отсутствуют"
        />
  );
}

