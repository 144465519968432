import React, {useEffect, useRef} from 'react';
import { useSelector } from 'react-redux';

import {
    Card, Loader, Button, Dimmer, Modal, Header, Icon
} from 'semantic-ui-react';
import {getOrders, getPlaceOrderInProgress} from "../../views/Orders/reducer";
import {createOrderQRCode} from "../../views/Orders/actions";
import {useReactToPrint} from "react-to-print";
import {BadgeToPrint} from "../PrintComponent/PrintComponent";

export default function OrderModalBadge(props) {

    const {openBadgeModal, setOpenBadgeModal, dispatch, orderID, userData, userLocation, mixpanel, token} = props;

    const orders =  useSelector( (state) => getOrders(state.orders));
    const order = orders.find((obj) => Number(obj.id) === Number(orderID),);
    const getLoading = useSelector((state) => getPlaceOrderInProgress(state.orders));

    useEffect(() => {
        if (!order || order.qrcode === '')
            createOrderBadge();
    }, [order]);


    function createOrderBadge() {
        const formObj = {
            user_location: JSON.stringify({
                userData: {id: userData.id, login: userData.login},
                userLocation,
            }),
        }
        dispatch(createOrderQRCode(token, orderID, formObj));

        if (mixpanel)
            mixpanel.track('OrderCard Create QR-code', { /*...currentExpoLocation,*/ ...userLocation, ...userData, id: orderID });
    }

    let componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <Modal
            className="modal-order-badge"
            closeIcon
            open={openBadgeModal}
            onClose={()=>setOpenBadgeModal(false)}
            onOpen={()=>setOpenBadgeModal(true)}
            dimmer
            centered
            size="fullscreen"
            closeOnDimmerClick={true}
        >
            <Header icon="id badge" content={`Этикетка для отвеса`} />
            <Modal.Content>
                <Dimmer active={ [1, Number(orderID)].includes(Number(getLoading)) } inverted>
                    <Loader inverted />
                </Dimmer>
                <Card className="order-badge" centered color="orange" raised>
                    <Card.Content>
                        <BadgeToPrint order={order} ref={componentRef} />
                    </Card.Content>
                </Card>
            </Modal.Content>
            <Modal.Actions>
                <Button size="big" color='red' onClick={()=>setOpenBadgeModal(false)}>
                    <Icon name='remove' /> Закрыть
                </Button>
                <Button size="big" color='green' onClick={handlePrint} disabled={[1, Number(orderID)].includes(Number(getLoading))}>
                    <Icon name='checkmark' /> Печать
                </Button>
            </Modal.Actions>
        </Modal>
    );
}