import 'whatwg-fetch';
import config, {AUTH_INVALID_MESSAGE, AUTH_INVALID_TOKEN, SERVICE_LOGICAL_ACCESS_DENIED} from '../../config/config';
import _ from "lodash";
import {toastr} from "react-redux-toastr";
import {dropToken} from "../../components/UserLogin/actions";

export const REQUEST_PRODUCTS = 'REQUEST_PRODUCTS';
export const RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS';
export const RECEIVE_1PAGE_PRODUCTS = 'RECEIVE_1PAGE_PRODUCTS';
export const PRODUCTS_PAGE_COUNT = 20;
export const REQUEST_PRODUCT_UPDATE = 'REQUEST_PRODUCT_UPDATE';
export const RECEIVE_PRODUCT_DATA = 'RECEIVE_PRODUCT_DATA';

export const requestProducts = (page) => ({
  type: REQUEST_PRODUCTS,
  page
});

export const receiveProducts = (products) => ({
  type: RECEIVE_PRODUCTS,
  products,
});

export const receive1PageProducts = (products) => ({
  type: RECEIVE_1PAGE_PRODUCTS,
  products,
});

export const requestProductUpdate = product_id => ({
  type: REQUEST_PRODUCT_UPDATE,
  product_id,
});

export const receiveProductData = product => ({
  type: RECEIVE_PRODUCT_DATA,
  product,
});

const process_api_error = (json, dispatch) => {
  dispatch(receiveProducts({}));
  if (json.code === AUTH_INVALID_TOKEN) {
    dispatch(dropToken());
    toastr.error(AUTH_INVALID_MESSAGE);
  } else
  if (json.code === SERVICE_LOGICAL_ACCESS_DENIED)
    toastr.error(json.message);
  else
    toastr.error('Ошибка сервера: ' + String(json.code) + '. ' + json.message);
};

export const fetchProducts = (params = {}) => (dispatch) => {
  dispatch(requestProducts(params.page ?? 1));

  let url;
  if (params && params.id) {
    url = config.API_PRODUCT_URL + String(params.id);
  } else {
    url = config.API_PRODUCTS_URL
      + '?'
      + Object.keys(params)
        .map((k) => k + '=' + encodeURIComponent(params[k]))
        .join('&');
  }

  return fetch(url)
    .then((response) => response.json())
    .then((json) => dispatch(params.page === 1 ? receive1PageProducts(json) : receiveProducts(json)))
    .catch(() => {
      dispatch(receiveProducts([]));
    });
};

export const fetchAllProducts = (params = {}) => (dispatch) => {
  dispatch(requestProducts(params.page));

  const url = config.API_PRODUCTS_URL
      + '?'
      + Object.keys(params)
        .map((k) => k + '=' + encodeURIComponent(params[k]))
        .join('&');
  return fetch(url)
    .then((response) => response.json())
    .then((json) => {
      dispatch(params.page === 1 ? receive1PageProducts(json) : receiveProducts(json));
      // рекурсия пока все не вычитаем
      if (json.length === params.per_page) {
        params.page ++;
        dispatch(fetchAllProducts(params));
      }
    })
    .catch(() => {
      dispatch(receiveProducts([]));
    });
};

export const createProductQRCode = (token, product_id, formObj) => (dispatch) => {
  dispatch(requestProductUpdate(product_id));

  const headers = { Authorization: 'Bearer ' + token };
  const url = config.API_CREATE_PRODUCT_QR_CODE_URL + '?product_id=' + String(product_id);
console.log('createProductQRCode url',url)
  const formData = new FormData();
  _.each( formObj, (value, key) => formData.append(String(key), value) );

  return fetch(url, { method: 'post', headers, body: formData, })
      .then((response) => response.json())
      .then((json) => {
        console.log('createProductQRCode json',json)
        if (json.code === 200) {
          dispatch(receiveProductData(json.data));
        } else {
          process_api_error(json, dispatch);
        }
      })
      .catch( (ex) => toastr.error('Ошибка запроса: ' + String(ex)) );
};