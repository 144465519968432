import 'whatwg-fetch';
import config, {AUTH_INVALID_MESSAGE, AUTH_INVALID_TOKEN, SERVICE_LOGICAL_ACCESS_DENIED} from '../../config/config';
import {dropToken} from "../../components/UserLogin/actions";
import {toastr} from "react-redux-toastr";
import _ from "lodash";

export const REQUEST_ORDERS_GROUPED = 'REQUEST_ORDERS_GROUPED';
export const RECEIVE_ORDERS_GROUPED = 'RECEIVE_ORDERS_GROUPED';
export const RECEIVE_1PAGE_ORDERS_GROUPED = 'RECEIVE_1PAGE_ORDERS_GROUPED';
export const ORDERS_GROUPED_PAGE_COUNT = 20;

export const requestOrdersGrouped = (page) => ({
    type: REQUEST_ORDERS_GROUPED,
    page
});

export const receiveOrdersGrouped = (ordersGrouped) => ({
    type: RECEIVE_ORDERS_GROUPED,
    ordersGrouped,
});

export const receive1PageOrdersGrouped = (ordersGrouped) => ({
    type: RECEIVE_1PAGE_ORDERS_GROUPED,
    ordersGrouped,
});

const process_api_error = (json, dispatch) => {
    dispatch(receiveOrdersGrouped({}));
    if (json.code === AUTH_INVALID_TOKEN) {
        dispatch(dropToken());
        toastr.error(AUTH_INVALID_MESSAGE);
    } else
        if (json.code === SERVICE_LOGICAL_ACCESS_DENIED)
            toastr.error(json.message);
        else
            toastr.error('Ошибка сервера: ' + String(json.code) + '. ' + json.message);
};

export const fetchOrdersGrouped = (token, params = {}) => (dispatch) => {

  dispatch(requestOrdersGrouped(params.page));

  const headers = token ? { Authorization: 'Bearer ' + token } : {};
  let url;
  if (params) {
      const url_query = '?'
      + Object.keys(params)
          .map( (k) => (k === 'id' ? '' : k + '=' + encodeURIComponent(params[k])) )
          .join('&');
      url = config.API_ORDERS_GROUPED_URL + url_query;
  } else
      url = config.API_ORDERS_GROUPED_URL;

  return fetch(url, {headers})
    .then((response) => response.json())
    .then((json) => {
        if (json.code === 200) {
          dispatch(params.page === 1 ? receive1PageOrdersGrouped(json.data) : receiveOrdersGrouped(json.data))
        } else {
          process_api_error(json, dispatch);
        }
    })
    .catch((ex) => {
      toastr.error('Ошибка запроса: ' + String(ex));
    });
};