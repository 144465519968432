export const ADD_PRODUCT = 'ADD_PRODUCT';
export const REMOVE_PRODUCT = 'REMOVE_PRODUCT';
export const REMOVE_ALL_PRODUCTS = 'REMOVE_ALL_PRODUCTS';

export const addProduct = (id, data) => ({
  type: ADD_PRODUCT,
  id,
  data,
});

export const removeProduct = (id) => ({
  type: REMOVE_PRODUCT,
  id,
});

export const removeAllProductsFromCart = () => ({
  type: REMOVE_ALL_PRODUCTS,
});

