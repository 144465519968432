import React, {useEffect, useState} from 'react';
import {Button, Form, Header, Icon, Modal, TextArea} from 'semantic-ui-react';
import './styles.css';
import {isUndefined} from "lodash";

export default function ModalPrompt(props) {
    const {open, headerIcon, headerText, messageText, handleYes, handleNo, handleClose, handleOpen, inputText, inputDefault} =
        props;
    const errorMsg = 'Поле обязательно для заполнения';
    const stateInit = {
        text: inputDefault ?? '',
        error: !isUndefined(inputText) && (isUndefined(inputDefault) || inputDefault.length === 0) ? errorMsg : ''
    }
    const [stateInput, setStateInput] = useState(stateInit);

    useEffect(() => {
        setStateInput(stateInit);
    }, [props]);

    function setField(value) {
        setStateInput({
            text: value,
            error: (value.length ? '' : errorMsg)
        });
    }

    return (
        <Modal
            closeIcon
            dimmer
            centered
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            closeOnDimmerClick={true}
            className="modal-prompt"
        >
            <Header icon={headerIcon} content={headerText} />
            <Modal.Content>
                <p>{messageText ?? ''}</p>
                { !isUndefined(inputText) && inputText.length ?
                    <Form>
                        <TextArea
                            className="modal-prompt-field modal-prompt-reason"
                            placeholder={inputText}
                            value={stateInput.text}
                            onChange={(e) => setField(e.target.value)}
                        />
                        <span className="modal-prompt-field-error">{stateInput.error}</span>
                    </Form> : '' }
            </Modal.Content>
            <Modal.Actions>
                <Button size="big" color='red' onClick={handleNo}>
                    <Icon name='remove' /> Нет
                </Button>
                <Button size="big" color='green' onClick={() => handleYes(stateInput.text)} disabled={stateInput.error !== ''}>
                    <Icon name='checkmark' /> Да
                </Button>
            </Modal.Actions>
        </Modal>
    );
}
