import React, {useState} from 'react';
import _ from 'lodash';
import {
  Card, Grid, Button, Icon, Input,
} from 'semantic-ui-react';
import CircularImage from '../../components/CircularImage';
import config from '../../config/config';

import './styles.css';

export default function OrderSummary(props) {

  const {order, quantity, show_buttons, scaled} = props;
  const [state, setState] = useState({
    saveInProgress: false,
  });

  function saveResult() {
    console.log('save scales for order', order);
    setState( {saveInProgress : true} );
  }

    return (
      <Card centered className="order-product-summary" color="blue">
        <Card.Content>
          <Grid doubling>
            <Grid.Row centered key={order.idOrder}>
              <Grid.Column width={11} className="first-column break-words" textAlign="left">
                ИТОГО
              </Grid.Column>
              <Grid.Column width={2} className="third-column">
                <div
                  dangerouslySetInnerHTML={{
                    __html: quantity / 1000 + ' кг',
                  }}
                />
              </Grid.Column>
              <Grid.Column width={2} textAlign="right" className="fourth-column">
                <div
                  dangerouslySetInnerHTML={{
                    __html: scaled / 1000 + ' кг',
                  }}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Card.Content>
      </Card>
    );
}

