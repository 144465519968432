import React from 'react';

import {
    Button,
    Header, Label,
} from 'semantic-ui-react';
import ModalPrompt from "../ModalPrompt";
import {updateOrder} from "../../views/Orders/actions";

export default function OrderCardHeader(props) {

    const {order, actionButtons, mixpanel, token, dispatch, userData, userLocation} = props;
    const {order_status, order_number, id} = order;
    const [open, setOpen] = React.useState(false);

    let orderLabelText = "не оформлен", labelColor = "red", icon = "";
    switch (order_status) {
        case 'processing':
            orderLabelText = "в работе";
            labelColor = "grey";
            icon = "trash";
            break;
        case 'completed':
            orderLabelText = "завершен";
            labelColor = "green";
            icon = "trash";
            break;
        case 'on-hold':
        case 'pending':
            orderLabelText = "ждет оплату";
            labelColor = "red";
            icon = "trash";
            break;
        case 'cancelled':
            orderLabelText = "отменен";
            labelColor = "brown";
            icon = "undo";
            break;
        default:
            break;
    }

    const orderDate = order.order_plant_date;

    function showPrompt() {
        setOpen(true);
        if (mixpanel)
            mixpanel.track('OrderCard Status Prompt', { ...userLocation, ...userData, order_status });
    }

    function orderState() {
        const data = {status: order_status === 'cancelled'? 'undo' : 'cancelled'};
        dispatch(updateOrder(token, id, JSON.stringify(data), order.order_key));
        setOpen(false);

        if (mixpanel)
            mixpanel.track('OrderCard Status Change', { ...userLocation, ...userData, ...data });
    }

    return (
        <div>
            <Header className="order-card-header" as="h3" color="red" textAlign="center">
                <div dangerouslySetInnerHTML={{ __html: '№ ' + String(order_number) + ' от ' + orderDate }} />
                { actionButtons ? <Button className="order-mini-card-cancel-button" icon={icon} onClick={showPrompt}/> : '' }
                <Label as="a" color={ labelColor } ribon="" attached="top left">{orderLabelText}</Label>
            </Header>
            <ModalPrompt
                open={open}
                headerIcon="question"
                headerText="Подтверждение"
                messageText={
                    order_status === 'cancelled'
                        ? `Вы хотите восстановить заказ №${order_number}?`
                        : `Действительно отменить заказ №${order_number}?`
                }
                handleYes={orderState}
                handleNo={() => setOpen(false)}
                handleClose={() => setOpen(false)}
                handleOpen={() => setOpen(true)}
            />
        </div>
    );
}